import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ImageFadeIn from "react-image-fade-in";
import Languaje from "../../utils/Languaje";
import classes from "./NeedSession.module.scss";

const NeedSession = ({ imagesProducts }) => {


  return (
    <React.Fragment>
      {/* <div className={classes.foodBackground}>
        <div className={classes.backgroundOpaque}> */}
          <Card className={classes.transformCardWarning}>
            <ImageFadeIn
              className={classes.cardImgPosition}
              src={`${window.location.origin}/img/mozoapp_2hb.svg`}
              alt={Languaje.t("title.app")}
            />
            <div className={classes.centerText}>
              <Typography className={classes.cardWelcome}>
                {Languaje.t("MozoBot.NeedSession.title")}
              </Typography>
              <Typography className={classes.cardExplication}>
                {Languaje.t("MozoBot.NeedSession.Explication")}
              </Typography>
             
            </div>
          </Card>
        {/* </div>
      </div> */}
    </React.Fragment>
  );
};

export default NeedSession;