import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import classesModule from "./InfoDrawer.module.scss";

export default function InfoDrawer({
  children,
  ...props
}) {

  return (
    <Drawer 
      anchor="left"
      open={props.isOpen}
      variant="temporary"
      onClose={props.closeDrawer}
      classes={{
        paper: classesModule.papper,
      }}
    >
      {children}
    </Drawer>
  );
}