import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import Helper from "../utils/Helper";
import { setRedirectPage } from "./../redux/actions/navigation";
import { PAGES } from "./../utils/constants";
export default function useService(promise, { defaultValue, ...props } = {}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [result, setResult] = useState(defaultValue);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const send = (params) => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            promise(params).then((result) => {
                setResult(result.data);
                setError(null);
                setLoading(false)
                resolve(result.data)
            }).catch((err) => {
                if (err === 403) {
                    dispatch(setRedirectPage(PAGES.PRODUCTS));
                    history.push(Helper.generateUrl(PAGES.LOGIN));
                    return;
                }
                setError(err);
                setResult(defaultValue);
                setLoading(false);
                reject(err)
            })
        });
    }



    return [send, result, loading,error];
}