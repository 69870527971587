import { useSelector } from "react-redux";
export default function useQr(promise, { defaultValue, ...props } = {}) {
    
    const qr = useSelector(store=> store.qr);
    
   
    const isDelivery  = ()=>{
        return qr.codeType == "delivery"
    }
    const isUnique  = ()=>{
        return qr.codeType == "unique"
    }
    const isTable  = ()=>{
        return qr.codeType == "tables"
    }

    return {
        qr,
        loading: false,
        isDelivery,
        isUnique,
        isTable
    } 
}