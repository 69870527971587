import _ from "lodash";
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { PAGES } from "./../../utils/constants"
const RedirectView = ({ ...props }) => {

    const qr = useSelector(state => _.get(state, `qr`));
    const getPageToRedirect = () => {
        if (_.isEmpty(qr)) {
            return PAGES.NEED_SESSION;
        }
        if (!_.isEmpty(qr)) {
            return PAGES.PRODUCTS;
        }
        return PAGES.NEED_SESSION;

    }
    return (
        <React.Fragment>
            <Redirect to={getPageToRedirect()} ></Redirect>
        </React.Fragment>
    );
}

export default RedirectView;