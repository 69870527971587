import React, { Component } from "react";

import classes from "./IconButton.module.scss"

import {IconButton as MaterialIconButton}from '@material-ui/core';

class IconButton extends Component {
    state = {

    };


    render() {

        return (
            <MaterialIconButton edge="start" className={classes.menuButton} onClick={this.props.onClick}>
                {this.props.children}
            </MaterialIconButton>
        );
    }
}

export default (IconButton)
