import ButtonFlat from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import _ from "lodash";
import React, { Component } from "react";
import { PAYMENT_METHODS } from "../../utils/constants";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import ServerConnect from "../../utils/ServerConnect";
import classes from "./Order.module.scss";



class Order extends Component {
    state = {

    };
    componentWillMount() {

    }
    getTotal(orderLines) {
        let total = 0;
        _.forEach(orderLines, orderLine => {
            total += orderLine.total_price_calc;
        });
        return total;
    }
    payWithMercadopago = () => {
        ServerConnect.requestApi("/v1/bot/app/generateMLPaymentUrl", {}, this.props.token, {}).then(result => {
            window.location = result.data.url;
            //window.open(result.data.url,"_blank");

        }).catch(err => {
            console.log("Err", err);
            this.props.enqueueSnackbar(Languaje.t(err.data.error), { variant: 'error' })
        })
    }
    render() {
        const { order, products, imagesProducts } = this.props;

        if (!order) {
            return null;
        }
        const orderLines = order.orderLines;
        return (
            <React.Fragment>
                <div className={classes.cardTitleWapper}>
                    <div className={classes.zone50}>
                        <div className={classes.dataOrder}>
                            {Languaje.t("product.titleSector")}
                        </div>
                        <div className={classes.dataOrder2}>
                            {Languaje.t("product.sector_status." + order.status)}
                        </div>
                    </div>
                    <div className={classes.zone50}>
                        <div className={classes.dataOrder}>
                            {Languaje.t("product.titleQRCode")}
                        </div>
                        <div className={classes.dataOrder2}>
                            #{order.uuid.substring(4, 8).toUpperCase()}
                        </div>
                    </div>
                </div>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    {orderLines.map((orderLine, i) => {
                        const product = _.find(products.rows, product => {
                            return product.id == orderLine.product_id;
                        });
                        if (!product) {
                            return null;
                        }
                        return (
                            <Grid item xs={12} sm={12} md={4} key={i}>
                                <Card className={i == 0 ? classes.transformCardSpecial : classes.transformCard}>
                                    <CardContent className={classes.specialContent}>
                                        <div className={classes.cardTitleWapper}>
                                            <div className={classes.cardTitle}>
                                                <Typography variant="h6" component="h3" className={classes.cardTitleText}>
                                                    <span className={classes.cantColor}>
                                                        {orderLine.cant}
                                                    </span>
                                                    <span className={classes.xColor}>
                                                        &nbsp; x &nbsp;
                                                    </span>
                                                    {product.name}
                                                </Typography>
                                                <div className={classes.cardDescription}>
                                                    {product.description}
                                                </div>
                                            </div>
                                            {imagesProducts[product.id] ?
                                                <div className={classes.cardAvatar}>
                                                    <div className={classes.avatarRounder} data-initial={Helper.CalculateInitials(product.name)}>
                                                        <img
                                                            src={imagesProducts[product.id]}
                                                            alt={product.name}
                                                            onError={(e) => {
                                                                
                                                            }
                                                            } />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </CardContent>
                                    {orderLine.notes ?
                                        <CardActions className={classes.cardActionsNotes}>
                                            <div className={classes.cardNotes}>
                                                <span className={classes.comentaryText}>{Languaje.t("title.comentary")}:</span>&nbsp;
                                                {orderLine.notes}
                                            </div>
                                        </CardActions>
                                        : null}
                                    <CardActions className={classes.cardActionsBtns}>
                                        <Typography variant="subtitle2" className={classes.currencyColor} component="p">
                                            {Languaje.t("product.subtotal")}: {Helper.CurrencyFormat(orderLine.total_price_calc)}
                                        </Typography>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>

                <div className={classes.cardTotal}>
                    <div className={classes.cardTotalFooter}>
                        {order.isPaid == false ? (
                            <span className={classes.paymentPending}>
                                {Languaje.t("MozoBot.payment.pending")}
                            </span>
                        ) : (
                                <span className={classes.paymentApproved}>
                                    {Languaje.t("MozoBot.payment.approved")}
                                </span>
                            )}
                        <section className={classes.footerCenter}>
                            {Languaje.t("MozoBot.TotalToPay")}: {Helper.CurrencyFormat(this.getTotal(orderLines))}
                        </section>
                    </div>
                </div>

                {order.isPaid == false && order.paymentMethod === PAYMENT_METHODS.MERCADOPAGO ? (
                    <ButtonFlat variant="contained" color="primary" className={classes.btnFlat} onClick={this.payWithMercadopago}>
                        {Languaje.t("MozoBot.button.mercadopago.pay")}
                    </ButtonFlat>
                ) : null}
            </React.Fragment>
        );
    }
}

export default (Order)
