import React, { createContext, useState } from 'react';
export const DataContext = createContext({});


export const DataProvider = ({ children, keyValue }) => {

  const [data, setData] = useState({});
  const updateKeyData = (key,value)=>{
    setData({...data,[key]:value})
  }

  return (
    <DataContext.Provider value={{ data,updateKeyData}}>
      {children}
    </DataContext.Provider>
  );
};


function useData() {
  const {  data,updateKeyData} = React.useContext(DataContext);
  return {   data,updateKeyData }
}

export default useData;