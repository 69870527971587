import _ from "lodash";
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';




const useIsComponentMounted = () => {
    const isMounted = React.useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []); // Using an empty dependency array ensures this only runs on unmount

    return isMounted.current;
};


const ControllerInput = ({ name, onChange, defaultValue, error, resetFields, render, as, innerRef, ...props }) => {
    const { control, getValues, watch, trigger, clearErrors, setValue: setValueForm, formState } = useFormContext();
    const { isSubmitting } = formState;
    const nameWatch = watch(name);

    const isMountComponent = useIsComponentMounted();

    useEffect(() => {
        isMountComponent && onChange && onChange(nameWatch, setValueForm);
        //TODO: Review  if this solution can apply with yup
        if (resetFields) {
            _.forEach(resetFields, element => {
                if (!(element.validate && !element.validate(nameWatch, getValues()))) {
                    trigger(element.field);
                    clearErrors(element.field);
                }
            })
        }
    }, [JSON.stringify(nameWatch)]);


 




    const customOnFocus = ()  => {
         props.onFocus && props.onFocus(nameWatch);
    }
    return (
        <React.Fragment>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ field, formState, fieldState }) => {
                    return React.createElement(as || render, {
                        ...field,
                        formState,
                        fieldState,
                        ...props,
                        innerRef,
                        errors: _.get(fieldState, `error`, {}),
                        onFocus:customOnFocus,
                        errorMessage: _.get(fieldState, `error.message`, error),
                        disabled: props.disabled || isSubmitting
                    });
                }}
            />
        </React.Fragment >
    )
};





export default ControllerInput;
