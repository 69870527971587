const PAGES = {
    NEED_SESSION:"/needSession",
    LOGIN: "/qr/login/:code_error?",
    PRODUCTS:"/qr/products",
    CONTINUE:"/continue/:qr",
    MYORDERS: "/qr/user/myorders",
    LOGOUT: "/qr/logout",
    NEW_OWNER: "/qr/newOwner",
    ERROR_SERVER:"/error",
    CART:"cart",
    ORDER:"order",
    
    PRODUCTS_NOTENABLED:"productsNotEnabled",
    ORDER_NOTENABLED:"orderNotEnabled",
    LOADING: "loading",
    
    
};
var QR_TYPES={
    UNIQUE:"unique",
    TABLES:"tables",
    DELIVERY:"delivery",
}
const PAYMENT_METHODS={
    CASH:"cash",
    MERCADOPAGO:"mercadopago",
}
const CLIENT_TYPE={
    ANONIMOUS:"ANONIMOUS",
    NEW_OWNER:"newOwner"
}
const ACTIONS = {
    "LOGIN":"login",
    "CHANGE_PHONE":"change_phone",
    "MENU_UP":"menu_up",
    "MENU_DOWN":"menu_down",
    "CALL_MOZO":"call_mozo",
    "CALL_COUNT":"call_count",
    VIEW_PRODUCTS:"view_products",
    VIEW_ORDER:"view_order",
    ADD_PRODUCT:"add_product",
    WHATSAPP:"whatsapp",
    GET_TABLE:"get_table",
}
const CODE_ERROR={
    TOKEN_ERROR: "TOKEN_ERROR"
}
export {
    PAGES,
    PAYMENT_METHODS,
    CLIENT_TYPE,
    ACTIONS,
    QR_TYPES,
    CODE_ERROR
}
