import { PAGES } from "./../../utils/constants";
const initialState = {
	redirect: {
		path: PAGES.PRODUCTS,
		params: null
	}
};
const apiReducer = (state = initialState, action) => {

	switch (action.type) {
		case 'SET_REDIRECT_PAGE': {
			return {
				redirect: {
					path: action.path,
					params: action.params
				}
			};
		}
		case 'CLEAR_REDIRECT_PAGE': {
			return {
				redirect: {
					path: PAGES.PRODUCTS,
					params: null
				}
			};
		}
		default:
			return state;
	}
};

export default apiReducer;
