import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
// import { FormProvider as FormCustomProvider } from './FormContext';
import DevtoolForm from '../../../dev/components/DevtoolForm'
import classes from './Form.module.scss'

  const FormContent = (
    (
      {
        onSubmit,
        children,
        defaultValues,
        schema,
        optionsForm = {},
        // Template = TemplateFormDefault,
        buttons = {},
        title,
        butonSubmitProps,
        propsTemplate
      },
      ref
    ) => {
      const methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: 'onChange',
        criteriaMode: 'firstErrorDetected',
        reValidateMode: 'onChange',
        shouldUnregister: true,
        shouldFocusError: true,
        ...optionsForm
      })
  
      
      
  
      const handleSubmit = async data => {
        if (onSubmit) {
          await onSubmit(data)
        }
      }
  
   
  
      return (
        <React.Fragment>
          <FormProvider {...methods}>
            <form
              
              autoComplete={'off'}
              className={classes.Form}
              onSubmit={methods.handleSubmit(handleSubmit)}
              noValidate={true}
            >
                  {/* <Template 
                      title = {title}
                      buttons={{ ...buttons, submit: <SubmitButton  {...butonSubmitProps}/> }} 
                      props={propsTemplate}
                  > */}
                      {children}
                  {/* </Template> */}
            </form>
            <DevtoolForm />
          </FormProvider>
        </React.Fragment>
      )
    }
  )
  
  export default FormContent
  