import ButtonFlat from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IcomoonReact from "icomoon-react";
import _ from "lodash";
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Scrollbar from "../../components/Scrollbar/Scrollbar";
import useData from "../../hooks/useData";
import ButtonSubmit from "../../modules/core/components/Buttons/ButtonSubmit";
import FormComponent from "../../modules/core/components/Form";
import ControllerInput from "../../modules/core/components/Form/ControllerInput";
import PlacesAutoComplete from "../../modules/core/components/Inputs/PlacesAutocomplete";
import TextInput from "../../modules/core/components/Inputs/TextInput";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import Languaje from "../../utils/Languaje";
import classes from "./DeliveryData.module.scss";

const theme = createMuiTheme({
  palette: {
    primary: { 500: "#D0051E" },
  },
  typography: {
    fontFamily: ["Google Sans"],
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: 16,
      },
    },
  },
});

const DeliveryData = ({ prevPanel, nextPanel }) => {

  const {updateKeyData} = useData();
  const user = useSelector(state => state.user);
  const [checkMail, setCheckMail] = useState(true);

  const schema = Yup.object().shape({
    name: Yup.string().required(
      Languaje.t("MozoBot.DeliveryData.validation.name.required")
    ),
    phone: Yup.string().required(
      Languaje.t("MozoBot.DeliveryData.validation.phone.required")
    )
    // ,
    // address: Yup.string().required(
    //   Languaje.t("MozoBot.DeliveryData.validation.address.required")
    // ),
  });

  const handleMakeAnOrder = async (values) => {
    updateKeyData("delivery",values);
    nextPanel();
  };

  const onChangeCheck = () => {
    setCheckMail(!checkMail);
  };

  const [view, setView] = useState('cash');

  const handleChange = (event, nextView) => {
    setView(nextView);
  };
  
  return (
    <React.Fragment>
      <div className={classes.allContainer}>
        <div className={classes.topContainer}>
          <h2 className={classes.titleCat}>
            {Languaje.t("MozoBot.DeliveryData.Modality")}
          </h2>
        </div>
        <FormComponent
          defaultValues={{
            deliveryMethod: "delivery",
            sendNotifications: "true",
            name: _.get(user,"name"),
            phone: _.get(user,"phone"),
            address: _.get(user,"address"),
            aditional_address: _.get(user,"aditional_address"),
          }}
          schema={schema}
          onSubmit={handleMakeAnOrder}
        >
          <div className={`${classes.infoContainer}`}>
            <Scrollbar>
              <div className={classes.formMargins}>
                <ToggleButtonGroup orientation="vertical" value={view} exclusive onChange={handleChange} className={`${classes.togglebtnfullwith}`}>
                  <ToggleButton value="cash" aria-label="cash" className={`${classes.toggletext}`}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={30}
                      icon={"Motorcycle-Delivery-Multiple-Boxes"}
                      color="#5b5b5b"
                    />
                    &nbsp;&nbsp;
                    {Languaje.t("MozoBot.DeliveryData.type.Delivery")}
                  </ToggleButton>
                  <ToggleButton value="mercadopago" aria-label="mercadopago" className={`${classes.toggletext}`}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={30}
                      icon={"take_away_food"}
                      color="#5b5b5b"
                    />
                    &nbsp;&nbsp;
                    {Languaje.t("MozoBot.DeliveryData.type.Pickup")}
                  </ToggleButton>
                </ToggleButtonGroup>
                {/* <div className={classes.formMargins}>
                  <div className={classes.btnsModalityContainer}>
                    <ControllerInput
                      name="deliveryMethod"
                      as={ToogleButtons}
                      // classNameButton={classes.btnFlatSelect}
                      options={[
                        {
                          id: "delivery",
                          label: (
                            <React.Fragment>
                              <IcomoonReact
                                iconSet={iconSet}
                                size={24}
                                icon={"Motorcycle-Delivery-Multiple-Boxes"}
                              />
                              &nbsp;&nbsp;
                              {Languaje.t("MozoBot.DeliveryData.type.Delivery")}
                            </React.Fragment>
                          ),
                        },
                        {
                          id: "local",
                          label: (
                            <React.Fragment>
                              <IcomoonReact
                                iconSet={iconSet}
                                size={24}
                                icon={"take_away_food"}
                              />
                              &nbsp;&nbsp;
                              {Languaje.t("MozoBot.DeliveryData.type.Pickup")}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div> */}
                <Typography
                  variant="subtitle2"
                  className={classes.specialsubtitle}
                  component="p"
                >
                  {Languaje.t("MozoBot.DeliveryData.PayData")}
                </Typography>
                <ControllerInput
                  render={({ ...field }) => (
                    <TextInput
                      label={Languaje.t("MozoBot.DeliveryData.Name")}
                      placeholder={Languaje.t("product.notes.placeholder")}
                      fullWidth
                      className={classes.TextInput}
                      margin="normal"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        style: {
                          padding: "30px 0px 10px",
                        },
                      }}
                      {...field}
                    />
                  )}
                  name="name"
                />
                <ControllerInput
                  render={({ ...field }) => (
                    <TextInput
                      type="number"
                      label={Languaje.t("MozoBot.DeliveryData.Phone")}
                      placeholder={Languaje.t("product.notes.placeholder")}
                      fullWidth
                      className={classes.TextInput}
                      margin="normal"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        style: {
                          padding: "30px 0px 10px",
                        },
                      }}
                      {...field}
                    />
                  )}
                  name="phone"
                />
                <ControllerInput
                  render={({ ...field }) => (
                    <PlacesAutoComplete
                      label={Languaje.t("MozoBot.DeliveryData.Address")}
                      placeholder={Languaje.t("product.notes.placeholder")}
                      fullWidth
                      className={classes.TextInput}
                      margin="normal"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        style: {
                          padding: "30px 0px 10px",
                        },
                      }}
                      {...field}
                    />
                  )}
                  name="address"
                />
                <ControllerInput
                  render={({ ...field }) => (
                    <TextInput
                      label={Languaje.t(
                        "MozoBot.DeliveryData.AditionalAddress"
                      )}
                      placeholder={Languaje.t("product.notes.placeholder")}
                      fullWidth
                      className={classes.TextInput}
                      margin="normal"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        style: {
                          padding: "30px 0px 10px",
                        },
                      }}
                      {...field}
                    />
                  )}
                  name="aditional_address"
                />
                <ControllerInput
                  name="comments"
                  render={({ ...field }) => (
                    <TextInput
                      label={Languaje.t("MozoBot.DeliveryData.Comments")}
                      placeholder={Languaje.t("product.notes.placeholder")}
                      multiline
                      fullWidth
                      className={classes.TextInput}
                      margin="normal"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      {...field}
                    />
                  )}
                />
                <div className={classes.checkSeparator}>
                  <ThemeProvider theme={theme}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <ControllerInput
                            name="sendNotifications"
                            render={({ ...field }) => (
                              <Checkbox
                                {...field}
                                checked={checkMail}
                                style={{
                                  color: "#D0051E",
                                }}
                                onChange={onChangeCheck}
                              />
                            )}
                          />
                        }
                        label={Languaje.t("MozoBot.DeliveryData.MailInfo")}
                      />
                    </FormGroup>
                  </ThemeProvider>
                </div>
                {!checkMail && (
                  <div
                    className={`${classes.checkSeparator} ${classes.checkSeparatorSpace}`}
                  >
                    <Alert severity="warning" className={classes.useFont}>
                      <AlertTitle className={classes.useFont}>
                        {Languaje.t("MozoBot.DeliveryData.warningTitle")}
                      </AlertTitle>
                      {Languaje.t("MozoBot.DeliveryData.warningText")}
                    </Alert>
                  </div>
                )}
              </div>
            </Scrollbar>
          </div>
          <div className={classes.bottomContainer}>
            <ButtonFlat
              variant="contained"
              color="primary"
              className={classes.btnFlat}
              onClick={prevPanel}
            >
              {Languaje.t("product.button.previous.step")}
            </ButtonFlat>
            <ButtonSubmit className={classes.btnFlat}>
              {Languaje.t("product.button.next.step")}
            </ButtonSubmit>
          </div>
        </FormComponent>
      </div>
    </React.Fragment>
  );
};

export default DeliveryData;
