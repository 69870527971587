import localstore from "./localstore";
const initialState = localstore.getItem("orders");
const apiReducer = (state = initialState, action) => {
	let  newOrders  = null;
	switch (action.type) {
		case 'ADD_ORDER_LINE': {
			newOrders = state?{...state}:{};
			const   {orderLine, client} = action.payload;
			if (!newOrders[client.id]){
				newOrders[client.id] = []
			}
			newOrders[client.id].push(orderLine);
			break;
		}
		case 'REMOVE_ORDER_LINE': {
			newOrders = state?{...state}:{};
			const   { client} = action.payload;
			if (newOrders[client.id]){
				newOrders[client.id].splice(action.index,1);
			}
			break;
		}
		case 'UPDATE_CANT_ORDER_LINE': {
			newOrders = state?{...state}:{};
			const   {index, client,cant} = action.payload;
			if (newOrders[client.id] && newOrders[client.id][index]){
				newOrders[client.id][index].cant=cant;
			}
			break;
		}
		
		case 'CLEAR_ORDER': {
			newOrders = state?{...state}:{};
			const   { client} = action.payload;
			newOrders[client.id] = []
			break;
		}
		default:
			break;
	}
	if (newOrders){
		localstore.setItem("orders", newOrders) ;
		return newOrders;
	}
	return state;
};

export default apiReducer;
