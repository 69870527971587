import React, { Component } from "react";
import classes from "./Message.module.scss";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
//import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "../../components";
class Message extends Component {
  state = {
    
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={true}
        autoHideDuration={5000}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              Mensaje
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit">
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
        );
      </Snackbar>
    );
  }
}

export default Message;