export default {
  palette: {
    primary: { 500: '#D0051E' },
    secondary: {
      light: '#FFFFFF',
      main: '#3A3F52',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiContainer:{
      root:{
        "padding-left": "7px",
        "padding-right": "7px",
      },
    },

    PrivateNotchedOutline: {
      root:{
        "border-radius": "6px",
        "border-width": "1.5px !important",
        "border-color": "#d71a36 !important",
        "font-family": "Google Sans !important",
      }, 
    },

    MuiInputBase: {
      root:{
        "font-family": "Google Sans !important",
        "color": "#565B6B !important",
        "font-weight": "500",
      },
    },

    MuiChip: {
      root:{
        "font-family": "Google Sans !important",
      },
    },

    MuiButton: {
      label:{
        "font-family": "Google Sans !important",
      },
    },
    MuiFormLabel: {
      root:{
        "font-family": "Google Sans !important",
        "font-weight": "500",
        "font-size": "18px !important",
        "text-transform": "uppercase",
        "&$focused": {
          color: "#d71a36 !important",
          "font-weight": "800",
        }
      },
    },
    MuiFilledInput: {
      root:{
        "background-color": "transparent !important",
      },
      underline: {
        '&:before': {
            borderBottom: '1px solid #565B6B'
        },
        '&:after': {
            borderBottom: '2px solid #d71a36'
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom:  '2px solid #565B6B'
        }
      },
      multiline: {
        "padding": "32px 0px 10px !important",
      },
    },
    MuiInputLabel : {
      shrink:{
        "transform": "translate(0px, 10px) scale(0.75) !important",
      },
    },
    MuiDrawer: {
      paper:{
        "overflow": "hidden !important",
      },
    },
  },
}