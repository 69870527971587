import en from './langs/en.json';
import es from './langs/es.json';

const defaultLanguage = 'es';
let currentLang = defaultLanguage;
let langs ={
    es,
    en
}

export default class Languaje {
    static  t(key,params) {
        if (langs[currentLang][key]){
            return langs[currentLang][key];
        }
        if (langs[defaultLanguage][key]){
            return langs[defaultLanguage][key];
        }
        return key;
    }
}
