import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from "./Loader.module.scss";
import { Box } from '@material-ui/core'
import Languaje from "../../utils/Languaje";

export default function Loader({
  topSpace10 = true,
  showText = true,
  waitText = Languaje.t("MozoBot.login.more.action"),
  useAlternativeColor = false,
}) {

  return (
    <React.Fragment>
      <div className={topSpace10 ? classes.topSpace : classes.noTopSpace}>
        <div className={classes.centerText}>
          <Box m="auto">
            <CircularProgress className={useAlternativeColor ? classes.loaderColorAlternative : classes.loaderColor} />
          </Box>
        </div>
        {showText && (
          <div className={`${useAlternativeColor ? classes.LoaderTextAlternative : classes.LoaderText} ${classes.centerText}`} >
            <Box m="auto">
              {waitText}
            </Box>
          </div>
        )}
      </div>
    </React.Fragment>
  )

}