import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { PAGES } from "../../utils/constants";
import Helper from "../../utils/Helper";
import services from "./../../services";

const Logout = ({ imagesProducts }) => {
  const dispatch = useDispatch();
  const qr = useSelector(state => state.qr);
  const history = useHistory();
  const redirectToLogin =async ()=>{
    await services.logout(qr, dispatch)
    history.push(Helper.generateUrl(PAGES.LOGIN));
  }
  useEffect(() => {
    redirectToLogin();
  }, [])

  return (
    <React.Fragment>
      
    </React.Fragment>
  );
};

export default Logout;