import { withSnackbar } from "notistack";
import React, { Component } from "react";

class Orders extends Component {
  state = {
    
  };

  render() {
    return (
      <React.Fragment>
        <div>Ordenes</div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Orders);