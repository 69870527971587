import React, { Component } from "react";

import classes from "./TagFilter.module.scss"

import {Button as MaterialButton}from '@material-ui/core';

class TagFilter extends Component {
    state = {

    };


    render() {

        return (
            <MaterialButton variant="contained" color="primary" className={classes.buttonNoShadow} onClick={this.props.onClick}>
                {this.props.children}
            </MaterialButton>
            
        );
    }
}

export default (TagFilter)
