import Languaje from "./Languaje";
import _ from "lodash";
import localstore from "./../redux/reducers/localstore";

export default class Helper {
  static CurrencyFormat(num) {
    const client = localstore.getItem("client");
    num = parseFloat(num);
    num = num.toFixed(2).replace(".", ",");
    num = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    var simbolo = _.get(client,"config.currencySymbol",Languaje.t("mozoBot.CurrencySimbol"));
    if (_.get(client,"config.showDecimal",true)===false){
      const decimal = parseInt(num.split(",")[1]);
      if (decimal==0){
        num = num.split(",")[0]
      }
    }
    return simbolo + " " + num;
  }

  static CalculateInitials(name) {
    if (name === undefined || name === null || name === 0) {
      return "?";
    } else {
      var names = name.split(" ");
      var firstname_letter = "";
      var lastname_letter = "";
      if (names[0]) {
        firstname_letter = names[0];
      }
      firstname_letter = firstname_letter
        .slice(0, firstname_letter.slice.length - 1)
        .toUpperCase();
      if (names[names.length - 1]) {
        lastname_letter = names[names.length - 1];
      }
      lastname_letter = lastname_letter
        .slice(0, lastname_letter.slice.length - 1)
        .toUpperCase();
      if (names.length == 1) {
        return firstname_letter;
      } else {
        return firstname_letter + lastname_letter;
      }
    }
  }

  static CalculateTotals(orderLines, addValue) {
    let total = 0;
    _.forEach(orderLines, (orderLine) => {
      total += orderLine.cant * orderLine.price;
    });
    if (addValue) {
      total = total + addValue;
    }
    return this.CurrencyFormat(total);
  }

  static Capitalizer(str, type) {
    if (type === "firstLetterOnly") {
      return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
    } else if (type === "firtAllWord") {
      const p2 = str
        .split(" ")
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(" ");
      return p2;
    } else if (type === "upper") {
      return str.toUpperCase();
    } else if (type === "firstWord") {
      return str.charAt(0).toUpperCase() + str.substring(1);
    }
  }

  static checkEmpty(srt) {
    var error;
    if (srt.length < 1 || srt == "" || srt == " ") {
      error = false;
    } else {
      error = true;
    }
    return error;
  }

  static onlyNumbers(e) {
    var keynum = window.event ? window.event.keyCode : e.which;
    if (keynum === 8 || keynum === 46) return true;
    return /\d/.test(String.fromCharCode(keynum));
  }

  static timeIntToSrt(time) {
    return time.toString().substr(0, time.toString().length - 2) + ":" + time.toString().substr(-2);
  }
  static generateUrl(path, params) {
    let url = path;
    _.forEach(params, (value, key) => {
      const paramKey = key == "_id" ? "id" : key;
      url = url.replace(new RegExp("((:" + paramKey + "(\\?)*$))", "g"), value)
      url = url.replace(new RegExp("((:" + paramKey + "(\\?)*\/))", "g"), value + "/")

    })
    url = url.replace(new RegExp("(:(\\w)+\\?(\\/)*)", "g"), "")

    return url;
  }
  static formateWeb(web, type) {
    var webFinal;
    if (type === "facebook") {
      webFinal = web.replace("https://www.facebook.com", "");
    }
    if (type === "twitter") {
      webFinal = web.replace("https://www.twitter.com", "");
    }
    if (type === "youtube") {
      webFinal = web.replace("https://www.youtube.com", "");
    }
    if (type === "instagram") {
      webFinal = web.replace("https://www.instagram.com", "");
    }
    return webFinal
  }
}
