//import React, { Component, useEffect, useState } from "react";
//import { connect, useDispatch, useSelector } from "react-redux";
import { Button as MaterialButton } from "@material-ui/core";
// import ServerConnect from "./../../utils/ServerConnect";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PhoneIcon from "@material-ui/icons/Phone";
import ReplayIcon from "@material-ui/icons/Replay";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithCredential, signInWithPopup } from "firebase/auth";
import _ from "lodash";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import ImageFadeIn from "react-image-fade-in";
//import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from "../../../components";
import { clearRedrirectPage } from "../../../redux/actions/navigation";
import * as userActions from "../../../redux/actions/user";
import services from "../../../services";
// import actions from "./../../redux/actions"
import { CLIENT_TYPE, PAGES } from "../../../utils/constants";
import Helper from "../../../utils/Helper";
import Languaje from "../../../utils/Languaje";
import classes from "./Login.module.scss";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCcT_OLgXjVs7iJ0-KBm7ZLxZb2g65zBpA",
//   authDomain: "mozoapp.firebaseapp.com",
//   projectId: "mozoapp",
//   storageBucket: "mozoapp.appspot.com",
//   messagingSenderId: "513901349767",
//   appId: "1:513901349767:web:68524937598084ee6c6122",
//   measurementId: "G-VTHT948C84"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const Login = (props) => {

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const qr = useSelector(state => state.qr);
  const [loginStep, setLoginStep] = useState(1);
  const [telephone, setTelephone] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { initialMinute = 5, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const redirect = useSelector(state => _.get(state, "navigation.redirect"));
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    userActions.setUser(null)
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  const resetTime = () => {
    setMinutes(5);
    setSeconds(0);
  }

  const componentClicked = (dataClick) => {

  }


  const firebaseLogin = async (provider) => {
    try {
      setLoadingData(true);
      const auth = getAuth(app);
      const credential = await signInWithPopup(auth, provider)
      const accessToken = credential.user.accessToken;
      const params = {
        first_name: credential._tokenResponse.firstName,
        last_name: credential._tokenResponse.lastName,
        accessToken,
        qr_uuid: qr.qr_uuid,
      }
      const user = await services.login(params, dispatch)
      if (user.type === CLIENT_TYPE.NEW_OWNER) {
        history.push(`${PAGES.NEW_OWNER}`);
        return;
      }
      if (redirect) {
        history.push(Helper.generateUrl(redirect.path, redirect.params));
      } else {
        history.push(Helper.generateUrl(PAGES.PRODUCTS));
      }
    } catch (e) {
      console.log(e)
      dispatch(clearRedrirectPage());
      history.push(`/error`);
      setLoadingData(false);
    }
  }

  const responseFacebook = async (response, a) => {
    if (response) {
      var params = {
        name: response.name,
        email: response.email,
        facebook_id: response.userID,
        qr_uuid: qr.qr_uuid,
        profile_pic: _.get(response, "picture.data.url"),
        first_name: response.name,
        signedRequest: response.signedRequest,
      }
      console.log('ASD =>', response)
      const auth = getAuth(app);
      const provider = new FacebookAuthProvider();
      const credential = await provider.credentialFromResult(response);
      console.log('ASD2 =>', credential)
      const firebaseLogin = await signInWithCredential(credential)
      console.log('ASD2 =>', firebaseLogin)
      throw 'STOP'
      services.login(params, dispatch).then(user => {
        if (user.type === CLIENT_TYPE.NEW_OWNER) {
          history.push(`${PAGES.NEW_OWNER}`);
          return;
        }
        if (redirect) {
          history.push(Helper.generateUrl(redirect.path, redirect.params));
        } else {
          history.push(Helper.generateUrl(PAGES.PRODUCTS));
        }
      }).catch(e => {
        history.push(`/error`);
      })
    }
  }

  const onChangePhone = (e) => {
    setTelephone(e.target.value);
  };

  const onChangeCheckCode = (e) => {
    setCheckCode(e.target.value);
  };

  const goMenu = () => {
    dispatch(clearRedrirectPage());
    history.push(Helper.generateUrl(PAGES.PRODUCTS));
  };

  const handleResendCode = () => {
    services.refreshActiveCodePhone({}).then(result => {
      resetTime();
      enqueueSnackbar(Languaje.t("MozoBot.login.more.snackCheckReSendSuccess"), {
        variant: "success"
      });
    }).catch(err => {
      enqueueSnackbar(Languaje.t("MozoBot.login.more.snackCheckReSendError"), {
        variant: "error"
      });
    });

  }
  const handleReturnProduct = () => {
    history.push(Helper.generateUrl(PAGES.PRODUCTS));
  }

  const handleSaveData = () => {
    if (Helper.checkEmpty(telephone)) {
      setLoginStep(5);
      var sd = services.addPhone({ phone: telephone });
      sd.then((value) => {
        setLoginStep(3);
        resetTime();
      }).catch((error) => {
        setLoginStep(2);
        enqueueSnackbar(Languaje.t("MozoBot.login.more.snackSaveMyPhoneError"), {
          variant: "error"
        });
      })
    } else {
      enqueueSnackbar(Languaje.t("MozoBot.login.more.noValid"), {
        variant: "error"
      });
    }
  };

  const handleVerifyCode = () => {
    if (Helper.checkEmpty(checkCode)) {
      setLoginStep(5);
      var vc = services.checkPhone({ code: checkCode });
      vc.then((value) => {
        setLoginStep(4);
        enqueueSnackbar(Languaje.t("MozoBot.login.more.snackSaveMyPhoneSuccess"), {
          variant: "success"
        });
      }).catch((error) => {
        setLoginStep(3);
        enqueueSnackbar(Languaje.t("MozoBot.login.more.snackCheckMyCodeError"), {
          variant: "error"
        });
      })
    } else {
      enqueueSnackbar(Languaje.t("MozoBot.login.more.noValid"), {
        variant: "error"
      });
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.transformCardWarning}>
        <ImageFadeIn
          className={classes.cardImgPosition}
          src={`${window.location.origin}/img/mozoapp_2hb.svg`}
          alt={Languaje.t("title.app")}
        />
        {loginStep === 1 && (
          <div className={classes.centerText}>
            {/* params */}
            {params.code_error ? (
              <React.Fragment>
                <Typography className={`${classes.cardWelcome} ${classes.Error}`}>
                  {Languaje.t(`MozoBot.login.error.${params.code_error}`)}
                </Typography>
                
              </React.Fragment>
            ) : (
              <Typography className={classes.cardWelcome}>
                {Languaje.t("MozoBot.login.welcome")}
              </Typography>
            )}
            <Typography className={classes.cardExplication}>
              {params.code_error ? (
                Languaje.t("MozoBot.login.explicaction2")
              ) : (
                Languaje.t("MozoBot.login.explicaction")
              )}
            </Typography>
            {loadingData ? (
              <Loader
                useAlternativeColor={true}
              ></Loader>
            ) : (
              <>
                <div className={classes.btnArea}>
                  <MaterialButton
                    variant="contained"
                    className={classes.btnFacebook}
                    onClick={() => {
                      const provider = new FacebookAuthProvider();
                      provider.addScope('email');
                      firebaseLogin(provider)
                    }}
                  >
                    <ImageFadeIn
                      className={classes.loginLogoService}
                      src={`${window.location.origin}/img/facebookLogo.svg`}
                    />
                    {Languaje.t("MozoBot.login.with.facebook")}
                  </MaterialButton>
                </div>
                <div className={classes.btnArea}>
                  <MaterialButton
                    variant="contained"
                    className={classes.btnGoogle}
                    onClick={() => {
                      const provider = new GoogleAuthProvider();
                      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
                      firebaseLogin(provider)
                    }}
                  >
                    <ImageFadeIn
                      className={classes.loginLogoService}
                      src={`${window.location.origin}/img/googleLogo.svg`}
                    />
                    {Languaje.t("MozoBot.login.with.google")}
                  </MaterialButton>
                </div>
                <div className={`${classes.btnArea}`}>
                  <MaterialButton
                    variant="contained"
                    className={classes.btnNormal}
                    onClick={() => handleReturnProduct()}
                  >
                    {Languaje.t("MozoBot.login.backProduct")}
                  </MaterialButton>
                </div>
                <div className={`${classes.btnArea} ${classes.hidden}`}>
                  <MaterialButton
                    variant="contained"
                    className={classes.btnGoogle}
                    onClick={() => setLoginStep(2)}
                  >
                    <ImageFadeIn
                      className={classes.loginLogoService}
                      src={`${window.location.origin}/img/googleLogo.svg`}
                    />
                    {Languaje.t("MozoBot.login.with.google")}
                  </MaterialButton>
                </div>
              </>
            )}
          </div>
        )}
        {loginStep === 2 && (
          <div className={classes.centerText}>
            <Typography className={classes.cardWelcome}>
              {Languaje.t("MozoBot.login.notifications")}
            </Typography>
            <Typography className={classes.cardExplication}>
              {Languaje.t("MozoBot.login.notifications.explication")}
            </Typography>
            <div className={classes.formContainer}>
              <form
                className={classes.formPosition}
                noValidate
                autoComplete="off"
              >
                <PhoneIcon className={classes.iconPos} />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  placeholder={Languaje.t(
                    "MozoBot.login.notifications.inputPhone"
                  )}
                  fontSize="20"
                  inputProps={{
                    style: {
                      color: "white",
                      marginLeft: "36px",
                      fontSize: "20px",
                      outline: "none",
                    },
                    maxLength: 12,
                  }}
                  value={telephone}
                  onChange={onChangePhone}
                />
              </form>
            </div>
            <div className={classes.btnArea}>
              <MaterialButton
                variant="contained"
                className={classes.btnNormal}
                onClick={() => handleSaveData()}
              >
                {Languaje.t("MozoBot.login.btnCheckPhone")}
              </MaterialButton>
            </div>
            <Typography className={classes.cardExplicationBottom}>
              {Languaje.t("MozoBot.login.notifications.skip")}
            </Typography>
            <MaterialButton
              variant="contained"
              className={classes.btnChangeStep}
              onClick={() => setLoginStep(4)}
            >
              {Languaje.t("MozoBot.login.notifications.btnSkip")}
              <NavigateNextIcon />
            </MaterialButton>
          </div>
        )}
        {loginStep === 3 && (
          <div className={classes.centerText}>
            <Typography className={classes.cardWelcome}>
              {Languaje.t("MozoBot.login.verify")}
            </Typography>

            <div>
              {minutes === 0 && seconds === 0
                ? (
                  <div>
                    <Typography className={classes.cardExplication}>
                      {Languaje.t("MozoBot.CodeExpired")}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.cardExplication}>
                      {Languaje.t("MozoBot.login.verify.explication")} {minutes}:{seconds < 10 ? `0${seconds}` : seconds} {Languaje.t("MozoBot.login.waitForCode")}
                    </Typography>
                    <div className={classes.formContainer}>
                      <form
                        className={classes.formPosition}
                        noValidate
                        autoComplete="off"
                      >
                        <LockOpenIcon className={classes.iconPos} />
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder={Languaje.t(
                            "MozoBot.login.verify.inputCode"
                          )}
                          fontSize="20"
                          inputProps={{
                            style: {
                              color: "white",
                              marginLeft: "36px",
                              fontSize: "20px",
                              outline: "none",
                            },
                            maxLength: 4,
                          }}
                          value={checkCode}
                          onChange={onChangeCheckCode}
                        />
                      </form>
                    </div>
                    <div className={classes.btnArea}>
                      <MaterialButton
                        variant="contained"
                        className={classes.btnNormal}
                        onClick={() => { handleVerifyCode() }}
                      >
                        {Languaje.t("MozoBot.login.verify.btnVerifyCode")}
                      </MaterialButton>
                    </div>
                  </div>
                )
              }
            </div>
            <div className={classes.centerText}>
              <MaterialButton
                variant="contained"
                className={classes.btnChangeStep}
                onClick={() => handleResendCode()}
              >
                <ReplayIcon />
                {Languaje.t("MozoBot.login.verify.btnReSend")}
              </MaterialButton>
            </div>
            <Typography className={classes.cardExplicationJoint}>
              {Languaje.t("MozoBot.login.verify.reSend")}
            </Typography>
            <div className={classes.centerText}>
              <MaterialButton
                variant="contained"
                className={classes.btnChangeStep}
                onClick={() => setLoginStep(2)}
              >
                <NavigateBeforeIcon />
                {Languaje.t("MozoBot.login.verify.btnChangePhone")}
              </MaterialButton>
            </div>
            <div className={classes.centerText}>
              <MaterialButton
                variant="contained"
                className={classes.btnChangeStep}
                onClick={() => setLoginStep(4)}
              >
                {Languaje.t("MozoBot.login.notifications.btnSkip")}
                <NavigateNextIcon />
              </MaterialButton>
            </div>
          </div>
        )}
        {loginStep === 4 && (
          <div className={classes.centerText}>
            <Typography className={classes.cardWelcome}>
              {Languaje.t("MozoBot.login.finish.title")}
            </Typography>
            <Typography className={classes.cardExplication}>
              {Languaje.t("MozoBot.login.finish.explication")}
            </Typography>
            <div className={classes.btnArea}>
              <MaterialButton
                variant="contained"
                className={classes.btnNormal}
                onClick={() => goMenu()}
              >
                {Languaje.t("MozoBot.login.finish.goMenu")}
                <NavigateNextIcon />
              </MaterialButton>
            </div>
          </div>
        )}
        {loginStep === 5 && (
          <div className={classes.centerText}>
            <Typography className={classes.cardWelcome}>
              {Languaje.t("MozoBot.login.more.action")}
            </Typography>
            <Typography className={classes.cardExplication}>
              {Languaje.t("MozoBot.login.wait")}
            </Typography>
            <Loader
              showText={false}
              topSpace10={false}
            >
            </Loader>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
}

export default Login;