import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import ApngComponent from "react-apng";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components";
import useOrders from "../../hooks/useOrders";
import useQr from "../../hooks/useQr";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import Scrollbar from "./../../components/Scrollbar/Scrollbar";
import { clearOrder } from "./../../redux/actions/orders";
import classes from "./MyOrders.module.scss";

const MyOrders = ({ imagesProducts, nextPanel }) => {

  const dispatch = useDispatch();
  // const [getMyOrder, orderLines, loading] = useService(services.getMyOrder, { defaultValue: [] });
  const [orderLines, setOrderLines] = useState([]);
  const { loading} = useQr();
  const { getOrders, removeOrderLine, updateCantOrderLine } = useOrders();
  const client = useSelector((store) => store.client);
  const [loadOrderLines, setLoadOrderLines] = useState(true);

  const setInitialData = async () => {
    const orders = await getOrders();
    setOrderLines(orders);
    setLoadOrderLines(false);
  };

  useEffect(() => {
    setInitialData();
  }, []);

  const handleMakeAnOrder = () => {
    nextPanel();
  };

  const handleClearOrder = () => {
    dispatch(clearOrder(client));
    setOrderLines([]);
  };

  const handleDeleteOrderLine = (index) => {
    removeOrderLine(index);
  };

  return (
    <React.Fragment>
      {loading || loadOrderLines ? (
        <Loader></Loader>
      ) : orderLines.length === 0 ? (
        <Card className={classes.transformCardSpecial}>
          <div className={classes.limitSpace}>
            <ApngComponent
              autoPlay={true}
              src={`${window.location.origin}/img/MozoNoOrders.png`}
            />
          </div>
          <div className={classes.centerText}>
            <Typography className={classes.cardTitleTextWarning}>
              {Languaje.t("MozoBot.Orders.Empty")}
            </Typography>
            <Typography className={classes.cardDescriptionWarning}>
              {Languaje.t("MozoBot.Orders.EmptyExplication")}
            </Typography>
          </div>
        </Card>
      ) : (
        <div className={classes.allContainer}>
          <div className={classes.topContainer}>
            <h2 className={classes.titleCat}>{Languaje.t("title.myorders")}</h2>
          </div>
          <div className={`${classes.infoContainer}`}>
            <Scrollbar>
              {orderLines.map((orderLine, i) => {
                return (
                  <>
                      <Grid item xs={12} key={i}>
                      <Card className={classes.transformCard}>
                        <CardContent className={classes.specialContent}>
                          <div className={classes.cardTitleWapper}>
                            <div className={classes.cardTitleExpand}>
                              <div>
                                <Typography
                                  variant="h6"
                                  component="h3"
                                  className={classes.cardTitleText}
                                >
                                  {orderLine.cant} X {orderLine.name}
                                </Typography>
                                {orderLine.notes !== "Sin notas" &&
                                  orderLine.notes.length > 1 && (
                                    <CardActions
                                      className={classes.cardActionsNotes}
                                    >
                                      <div className={classes.cardNotes}>
                                        <span className={classes.comentaryText}>
                                          {Languaje.t("title.comentary")}:
                                        </span>
                                        &nbsp;
                                        {orderLine.notes}
                                      </div>
                                    </CardActions>
                                  )}
                              </div>
                            </div>
                            <div>
                            <Typography
                              variant="subtitle2"
                              className={classes.currencyColor}
                              component="p"
                            >
                              {Helper.CurrencyFormat(
                                orderLine.price * orderLine.cant
                              )}
                            </Typography>
                            </div>
                          </div>
                          
                        </CardContent>
                      </Card>
                    </Grid>
                    
                  </>
                );
              })}
            </Scrollbar>
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.priceData}>
              <div className={classes.spaceBtnPlus}>
                
              </div>
              <div className={classes.priceText}>
                {Languaje.t("MozoBot.TotalToPay")}:&nbsp;
                <span className={classes.priceColor}>
                  {Helper.CalculateTotals(orderLines)}
                </span>
              </div>
            </div>
           
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyOrders;
