import React, { useEffect, useState } from 'react';
import useOrders from '../../hooks/useOrders';
import useQr from '../../hooks/useQr';
import Helper from '../../utils/Helper';
import Languaje from "../../utils/Languaje";
import ManagerAccess from '../Managers/ManagerAccess/ManagerAccess';
import classes from "./FloatPrice.module.scss";
export default function FloatPrice({

  onClick,
  ...props
}) {
  const [priceValue, setPriceValue] = useState();
  const [cant, setCant] = useState()
  const { qr, isDelivery, isUnique } = useQr();
  const { ordersMemory } = useOrders();
  useEffect(() => {
    if (qr) {
      if (isDelivery() || isUnique()) {
        setInitialData()
      }
    }


  }, [JSON.stringify(qr), JSON.stringify(ordersMemory)])
  const setInitialData = async () => {
    setPriceValue(Helper.CalculateTotals(ordersMemory))
    setCant(ordersMemory.length ? ordersMemory.length : 0)
  };

  return (
    <>
      <ManagerAccess action={"view_order"}>
        {cant && (
          <div className={classes.floatPrice} onClick={onClick}>
            <div
              className={classes.fabPrice}
            >
              {Languaje.t("MozoBot.TotalToPay") + ": " + priceValue}
            </div>
            <div
              className={classes.fabCant}
            >
              {cant > 1 ? (
                <>
                  {cant + " " + Languaje.t("MozoBot.products.addLoading2")}
                </>
              ) : (
                <>
                  {cant + " " + Languaje.t("MozoBot.products.addLoading3")}
                </>
              )}
            </div>
          </div>
        )}
      </ManagerAccess>
    </>
  );
}