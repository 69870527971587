import React from 'react';
import classes from "./InfoItem.module.scss";
import BtnRounder from '../BtnRounder/BtnRounder';

export default function InfoItem({
  showButton = false,
  rowCount = "1",
  iconColor = "rgba(0, 0, 0, 0.87)",
  ...props
}) {

  return (
    <React.Fragment>
      <div className={classes.wapper}>
        <div className={classes.infoContainer}>
          {rowCount === "1" && (
            <React.Fragment>
              <span className={classes.isBold}>{props.itemTitle}:</span>&nbsp;{props.itemDetail}
            </React.Fragment>
          )} 
          {rowCount === "2" && (
            <React.Fragment>
              <span className={classes.isBold}>{props.itemTitle}:</span><br></br>{props.itemDetail}
            </React.Fragment>
          )}        
          {rowCount === "3" && (
            <React.Fragment>
              <span className={classes.isBold}>{props.itemTitle}:</span><br></br>{props.itemDetail}<br></br>{props.itemRowAdicional}
            </React.Fragment>
          )}        
        </div>
        {showButton && (
          <div className={classes.iconContainer}>
            <BtnRounder
              btnColor={iconColor}
              btnSize={props.iconSize}
              btnIcon={props.iconName}
              btnAction={props.action}
            >
            </BtnRounder>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}