import { TextField } from '@material-ui/core'
import React from 'react'
import ErrorInput from '../../ErrorInput'
import classesModules from './TextInput.module.scss'




const TextInput = ({errorMessage,...props}) => {
 
    return (
        <React.Fragment>
            <div
                className={`${classesModules.TextField}`}
            >
                <TextField {...props} />
                <ErrorInput
                    message={errorMessage}
                    classNameError={classesModules.error}
                />
            </div>
        </React.Fragment>
    )
}

export default TextInput;
