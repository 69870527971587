import React from "react";
import classes from "./InfoLocal.module.scss";
import ImageFadeIn from "react-image-fade-in";
import Languaje from "../../utils/Languaje";
import ServerConnect from "../../utils/ServerConnect";
import Link from '@material-ui/core/Link';
import InfoItem from "../InfoItem/InfoItem";
import ReactStars from 'react-stars'
import { useSelector } from "react-redux";
import Helper from "../../utils/Helper";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import _ from "lodash";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import { Fab } from "@material-ui/core";
import IcomoonReact from "icomoon-react";
import ReactGA from "react-ga";

export default function InfoLocal({
  ...props
}) {
  const client = useSelector(store => _.get(store, "client"));

  const handleAddress = () => {

    ReactGA.event({
      category: 'Usuario',
      action: 'handleAddress()'
    });

    props.closeDrawer();
    var reformerLink = client.address.replace(" ", "+");
    if (isMobile) {
      if (isAndroid ) {
        reformerLink = "geo:0,0?q=" + reformerLink;
      } 
      if (isIOS) {
        reformerLink = "http://maps.google.com/?saddr=Current%20Location&daddr=" + reformerLink;
      }
    } else {
      reformerLink = "https://www.google.com/maps/search/?api=1&query=" + reformerLink;
    }
    window.open(reformerLink, "_black");
  }

  const handleWhatsapp = () => {
    ReactGA.event({
      category: 'Usuario',
      action: 'handleWhatsapp()'
    });

    props.closeDrawer();
    window.open('https://api.whatsapp.com/send?phone=' + client.whatsapp, "_black");
  }

  const handleCall = () => {
    ReactGA.event({
      category: 'Usuario',
      action: 'handleCall()'
    });

    props.closeDrawer();
    window.open("tel:" + client.phone, "_black");
  }

  const handleMail = () => {
    ReactGA.event({
      category: 'Usuario',
      action: 'handleMail()'
    });
    props.closeDrawer();
    window.open("mailto:" + client.location_email, "_black");
  }

  const handleSocial = (url) => {
    ReactGA.event({
      category: 'Usuario',
      action: 'handleSocial()'
    });
    props.closeDrawer();
    window.open(url, "_black");
  }

  return (
    <React.Fragment>
      <div className={classes.drawerWapper}>
        <div className={classes.drawerHeader}>
          <Fab
            size="small"
            className={classes.btnRounderBorder}
            onClick={() => props.closeDrawer()}
          >
            <IcomoonReact
              iconSet={iconSet}
              color="#666"
              size={18}
              icon="close"
            />
          </Fab>
        </div>
        <div className={classes.drawerContainer}>
          <div className={classes.newHeader}>
            {client.logo && (
              <div className={classes.cardAvatar}>
                <div className={classes.avatarRounder} data-initial={Helper.CalculateInitials(client.name)}>
                  <img
                    src={ServerConnect.getUrlImage(client.logo,"logo")}
                    alt={client.name}
                    onError={(e) => {
                     
                    }}
                  />
                </div>
              </div>
             )}
            <div className={classes.columData}>
              <div className={classes.headerLocal}>
                {client.name}
              </div>
              {client.ranking && (
                <div className={classes.startsPos}>
                  <ReactStars
                    count={5}
                    size={24}
                    color2={'#ffd700'}
                    color1={'#b4b6be'}
                    value={client.ranking}
                    edit={false}
                  />
                  <div className={classes.startDescription}>
                    {Languaje.t("MozoBot.ranking." + client.ranking)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.titleCat}>{Languaje.t("MozoBot.InfoArea.Contact")}</div>
          {client.address ? (
            <React.Fragment>
              <InfoItem
                itemTitle={Languaje.t("MozoBot.Info.Direction")}
                itemDetail={client.address}
                showButton={true}
                iconName="place"
                iconSize="24"
                action={handleAddress}
              >
              </InfoItem>
            </React.Fragment>
          ): null}
          {client.whatsapp ? (
            <React.Fragment>
              <InfoItem
                itemTitle={Languaje.t("MozoBot.Info.WhatsApp")}
                itemDetail={client.whatsapp}
                rowCount="2"
                showButton={true}
                iconName="whatsapp"
                iconSize="18"
                action={handleWhatsapp}
              >
              </InfoItem>
            </React.Fragment>
          ): null}
          {client.phone ? (
            <React.Fragment>
              <InfoItem
                itemTitle={Languaje.t("MozoBot.Info.WorkPhone")}
                itemDetail={client.phone}
                rowCount="2"
                showButton={true}
                iconName="call"
                iconSize="22"
                action={handleCall}
              >
              </InfoItem>
            </React.Fragment>
          ): null}
          {client.location_email ? (
            <React.Fragment>
              <InfoItem
                itemTitle={Languaje.t("MozoBot.Info.Email")}
                itemDetail={client.location_email}
                rowCount="2"
                showButton={true}
                iconName="email"
                iconSize="22"
                action={handleMail}
              >
              </InfoItem>
            </React.Fragment>
           ): null}
          {client["Socials"].length !== 0 ? (
            <React.Fragment>
              <div className={classes.titleCat}>{Languaje.t("MozoBot.InfoArea.Social")}</div>
              {_.map(_.get(client, "Socials", []), (social, i) => (
                <InfoItem
                  key={i}
                  itemTitle={Languaje.t("MozoBot.Info." + social.type)}
                  itemDetail={Helper.formateWeb(social.url, social.type)}
                  rowCount="2"
                  showButton={true}
                  iconName={social.type}
                  iconSize="18"
                  action={() => {
                    handleSocial(social.url);
                  }}
                >
                </InfoItem>
              ))}
            </React.Fragment>
          ): null}
          {client["Wifis"].length !== 0 ? (
            <React.Fragment>
              <div className={classes.titleCat}>{Languaje.t("MozoBot.InfoArea.Internet")}</div>
              {_.map(_.get(client, "Wifis", []), (wifi, i) => (
                <InfoItem
                  itemTitle={`${Languaje.t("MozoBot.Info.Wifi")} ${wifi.zone}`}
                  itemDetail={`${Languaje.t("MozoBot.Info.NameAP")}: ${wifi.name}`}
                  itemRowAdicional={`${Languaje.t("MozoBot.Info.Contraseña")}: ${wifi.password}`}
                  rowCount="3"
                  showButton={false}
                  iconName="wifi"
                  iconSize="20"
                >
                </InfoItem>
              ))}
            </React.Fragment>
          ): null}
          {client["OpenHours"].length !== 0 ? (
            <React.Fragment>
              <div className={classes.titleCat}>{Languaje.t("MozoBot.InfoArea.Hours")}</div>
              {_.map(_.get(client, "OpenHours", []), (hour, i) => (
                <InfoItem
                  itemTitle={Languaje.t(`MozoBot.InfoArea.${hour.day}`)}
                  itemDetail={"de " + Helper.timeIntToSrt(hour.open) + " a " + Helper.timeIntToSrt(hour.close) + " Hs."}
                  rowCount="1"
                  showButton={false}
                ></InfoItem>
              ))}
            </React.Fragment>
          ): null}
        </div>
        <div className={classes.drawerFooter}>
          <div className={classes.logoCenter}>
            <ImageFadeIn
              src={`${window.location.origin}/img/mozoapp_2hr.svg`}
              alt={Languaje.t("title.app")}
              className={classes.toolbarLogo}
            />
          </div>
          <Link rel="noopener noreferrer" href="https://www.mozoapp.com/" target="_blank" className={classes.mozoLink}>
            www.mozoapp.com
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
