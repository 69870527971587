import React from "react";
import classes from "./LayoutImage.module.scss";

const LayoutImage = ({ children }) => {

  return (
    <React.Fragment>
      <div className={classes.foodBackground}>
        <div className={classes.backgroundOpaque}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LayoutImage;