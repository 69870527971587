
import _ from "lodash";
import React from 'react';
import { useSelector } from "react-redux";
import {Login,Continue} from "./../"


const ContinueLogin = (props) => {
  const accessToken = useSelector(state => _.get(state,"session.accessToken"));
  return (
    <React.Fragment>
      {accessToken && <Continue/>}
      {!accessToken && <Login/>}
        
    </React.Fragment>
  );
}

export default ContinueLogin;