import ButtonFlat from "@material-ui/core/Button";
import IcomoonReact from "icomoon-react";
import _ from "lodash";
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import * as Yup from "yup";
import useData from "../../hooks/useData";
import ButtonSubmit from "../../modules/core/components/Buttons/ButtonSubmit";
import ControllerInput from "../../modules/core/components/Form/ControllerInput";
import TextInput from "../../modules/core/components/Inputs/TextInput";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import Languaje from "../../utils/Languaje";
import Scrollbar from "../../components/Scrollbar/Scrollbar";
import FormComponent from "../../modules/core/components/Form";
import classes from "./DeliveryData.module.scss";
import Typography from "@material-ui/core/Typography";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const PayData = ({ prevPanel, nextPanel }) => {

  const {updateKeyData} = useData();
  const user = useSelector(state => state.user);

  const schema = Yup.object().shape({
    // paywith: Yup.string().required(
    //   Languaje.t("MozoBot.PayData.validation.phone.required")
    // )
  });

  const handleMakeAnOrder = async (values) => {
    updateKeyData("delivery",values);
    nextPanel();
  };

  const [view, setView] = useState('cash');

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  return (
    <React.Fragment>
      <div className={classes.allContainer}>
        <div className={classes.topContainer}>
          <h2 className={classes.titleCat}>
            {Languaje.t("MozoBot.PayData.mode")}
          </h2>
        </div>
        <FormComponent
          defaultValues={{
            deliveryMethod: "delivery",
            sendNotifications: "true",
            name: _.get(user,"name"),
            phone: _.get(user,"phone"),
            address: _.get(user,"address"),
            aditional_address: _.get(user,"aditional_address"),
          }}
          schema={schema}
          onSubmit={handleMakeAnOrder}
        >
          <div className={`${classes.infoContainer}`}>
            <Scrollbar>
              <div className={classes.formMargins}>
                <ToggleButtonGroup orientation="vertical" value={view} exclusive onChange={handleChange} className={`${classes.togglebtnfullwith}`}>
                  <ToggleButton value="cash" aria-label="cash" className={`${classes.toggletext}`}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={30}
                      icon={"pay"}
                      color="#5b5b5b"
                    />
                    &nbsp;&nbsp;
                    {Languaje.t("MozoBot.PayData.pay.with.cash")}
                  </ToggleButton>
                  {/* <ToggleButton value="mercadopago" aria-label="mercadopago" className={`${classes.toggletext}`}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={30}
                      icon={"mercadopago"}
                      color="#5b5b5b"
                    />
                    &nbsp;&nbsp;
                    {Languaje.t("MozoBot.PayData.pay.with.mercadopago")}
                  </ToggleButton> */}
                </ToggleButtonGroup>
                {view === "cash" && (
                  <>
                    <ControllerInput
                      render={({ ...field }) => (
                        <TextInput
                          type="number"
                          label={Languaje.t("MozoBot.PayData.pay.with")}
                          placeholder={Languaje.t("product.notes.placeholder")}
                          fullWidth
                          className={classes.TextInput}
                          margin="normal"
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            style: {
                              padding: "30px 0px 10px",
                            },
                          }}
                          {...field}
                        />
                      )}
                      name="paywith"
                    />
                    <Typography
                      variant="subtitle2"
                      className={classes.specialsubtitlepay}
                      component="p"
                    >
                      {Languaje.t("MozoBot.PayData.pay.hit")}
                    </Typography>
                  </>
                )}
              </div>
            </Scrollbar>
          </div>
          <div className={classes.bottomContainer}>
            <ButtonFlat
              variant="contained"
              color="primary"
              className={classes.btnFlat}
              onClick={prevPanel}
            >
              {Languaje.t("product.button.previous.step")}
            </ButtonFlat>
            <ButtonSubmit className={classes.btnFlat}>
              {Languaje.t("product.button.next.step")}
            </ButtonSubmit>
          </div>
        </FormComponent>
      </div>
    </React.Fragment>
  );
};

export default PayData;
