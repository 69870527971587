import React from "react";
import classesModule from "./DrawerPage.module.scss";
import Drawer from "@material-ui/core/Drawer";
import Scrollbar from "./../../components/Scrollbar/Scrollbar";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import { Fab } from "@material-ui/core";
import IcomoonReact from "icomoon-react";

const DrawerPage = ({
  children,
  open,
  close,
  ...props
}) => {

  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={close}
        classes={{
          paper: classesModule.papper,
        }}
      >
        <div className={classesModule.contentDrawer}>
          <Fab
            size="small"
            className={classesModule.btnRounderBorder}
            onClick={close}
          >
            <IcomoonReact
              iconSet={iconSet}
              color="#666"
              size={18}
              icon="close"
            />
          </Fab>
          <Scrollbar>
            {props.saveArea ? (
              <div className={classesModule.limitSpace}>
                {children}
              </div>
            ) : (
              children
            )}
          </Scrollbar>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
export default DrawerPage;
