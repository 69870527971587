import React from "react";
import { Content, Header, InfoDrawer, InfoLocal } from "../components";
import classes from "./Containers.module.scss";

const LayoutApp = ({ children, title, back, openDrawer, handleDrawer }) => {

  return (
    <React.Fragment>
      <InfoDrawer isOpen={openDrawer} closeDrawer={handleDrawer}>
        <InfoLocal closeDrawer={handleDrawer}></InfoLocal>
      </InfoDrawer>
      <Header title={title} back={back} showDrawer={true}></Header>
      <Content>
        <div className={classes.scrContainer}>{children}</div>
      </Content>
    </React.Fragment>
  );
};

export default LayoutApp;
