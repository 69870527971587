import React from 'react';
import Fab from '@material-ui/core/Fab';
import classes from "./BtnRounder.module.scss";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import IcomoonReact from "icomoon-react";

export default function BtnRounder({
  ...props
}) {

  return (
    <Fab size="small" className={classes.specialFab} onClick={props.btnAction}>
      <IcomoonReact
        iconSet={iconSet}
        color={props.btnColor}
        size={props.btnSize}
        icon={props.btnIcon}
      />
    </Fab>
  );
}