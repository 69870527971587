import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import LayoutImage from "./components/LayoutImage/LayoutImage";
import ManagerRoute from "./components/Managers/ManagerRoute/ManagerRoute";
import { Code, LayoutApp, Mensajes, Products,ContinueLogin } from "./containers";
import Logout from "./containers/Logout/Logout";
import NeedSession from "./containers/NeedSession/NeedSession";
import OwnerTaken from "./containers/OwnerTaken/OwnerTaken";
import RedirectView from "./containers/RedirectView/RedirectView";
import { Store } from "./redux/Store";
import theme from "./theme/theme";
import { PAGES } from "./utils/constants";
import Languaje from "./utils/Languaje";
import Slide from "@material-ui/core/Slide";

const materialTheme = createMuiTheme(theme);
const GAtrackingID = process.env.REACT_APP_GA_TRAKING_ID || "";

const App = (props) => {
  useEffect(() => {
    ReactGA.initialize(GAtrackingID);
  }, []);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Provider store={Store}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
      >
        <ThemeProvider theme={materialTheme}>
          <div>
            <BrowserRouter>
              <Switch>
                <ManagerRoute exact path={`${PAGES.NEED_SESSION}`}>
                  <LayoutImage>
                    <NeedSession />
                  </LayoutImage>
                </ManagerRoute>
                <ManagerRoute exact path={`${PAGES.LOGIN}`}>
                  <LayoutImage>
                    <ContinueLogin />
                  </LayoutImage>
                </ManagerRoute>
                <ManagerRoute exact path={`${PAGES.LOGOUT}`}>
                  <LayoutImage>
                    <Logout />
                  </LayoutImage>
                </ManagerRoute>
                <ManagerRoute exact path={`${PAGES.PRODUCTS}`}>
                  <LayoutApp
                    title={Languaje.t("title.products")}
                    back={null}
                    openDrawer={openDrawer}
                    handleDrawer={handleDrawer}
                  >
                    <Products handleDrawer={handleDrawer}></Products>
                  </LayoutApp>
                </ManagerRoute>
                <ManagerRoute exact path={`${PAGES.ERROR_SERVER}`}>
                  <LayoutApp
                    title={Languaje.t("title.products")}
                    openDrawer={openDrawer}
                    back={{ path: PAGES.PRODUCTS }}
                    handleDrawer={handleDrawer}
                  >
                    <Mensajes
                      title="MozoBot.GeneralErrorTitle"
                      subtitle="MozoBot.GeneralErrorSubtitle"
                      status="error"
                    />
                  </LayoutApp>
                </ManagerRoute>
                <ManagerRoute exact path={`${PAGES.NEW_OWNER}`}>
                  <LayoutImage>
                    <OwnerTaken />
                  </LayoutImage>
                </ManagerRoute>
                <ManagerRoute exact path="/mp-payment-success">
                  <Mensajes
                    title="MozoBot.payment.success.title"
                    subtitle="MozoBot.payment.success.subtitle"
                    status="success"
                  />
                </ManagerRoute>
                <ManagerRoute exact path="/mp-payment-failure">
                  <Mensajes
                    title="MozoBot.payment.failure.title"
                    subtitle="MozoBot.payment.failure.subtitle"
                    status="error"
                  />
                </ManagerRoute>
                <ManagerRoute exact path="/code/:qr">
                  <Code />
                </ManagerRoute>
              
                <ManagerRoute exact path="/">
                  <RedirectView />
                </ManagerRoute>
                <ManagerRoute exact>
                  <Mensajes
                    title="MozoBot.GeneralErrorTitle"
                    subtitle="MozoBot.GeneralErrorSubtitle"
                    status="error"
                  />
                </ManagerRoute>
              </Switch>
            </BrowserRouter>
          </div>
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
