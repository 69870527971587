import { Button as MaterialButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useState } from 'react';
import ImageFadeIn from "react-image-fade-in";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../../components";
import { setAccessToken } from "../../../redux/actions/session";
import { setUser } from "../../../redux/actions/user";
import services from "../../../services";
import { CLIENT_TYPE, CODE_ERROR, PAGES } from "../../../utils/constants";
import Helper from "../../../utils/Helper";
import Languaje from "../../../utils/Languaje";
import classes from "./Continue.module.scss";

const Continue = (props) => {

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loadingData, setLoadingData] = useState(false);

  const accessToken = useSelector((state) =>
    _.get(state, "session.accessToken")
  );

  const qr = useSelector((state) => _.get(state, "qr"));
  const redirect = useSelector((state) => _.get(state, "navigation.redirect"));

  const handleUseAnotherAccount = async () => {
    const newUser = await services.crearAccessToken(dispatch);
  };

  const handleContinueLogin = async () => {
    const qr_uuid = _.get(qr, "qr_uuid");
    const body = {
      first_name: user.first_name,
      last_name: user.last_name,
      accessToken,
      qr_uuid: qr_uuid,
    };
    try {
      setLoadingData(true)
      const newUser = await services.login(body, dispatch);
      if (newUser.type === CLIENT_TYPE.NEW_OWNER) {
        history.push(`${PAGES.NEW_OWNER}`);
        return;
      }
      if (redirect) {
        history.push(Helper.generateUrl(redirect.path, redirect.params));
      } else {
        history.push(Helper.generateUrl(PAGES.PRODUCTS));
      }
    } catch (e) {
      dispatch(setUser(null));
      dispatch(setAccessToken(null));
      history.push(
        Helper.generateUrl(PAGES.LOGIN, { code_error: CODE_ERROR.TOKEN_ERROR })
      );
      setLoadingData(false)
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.transformCardWarning}>
        <ImageFadeIn
          className={classes.cardImgPosition}
          src={`${window.location.origin}/img/mozoapp_2hb.svg`}
          alt={Languaje.t("title.app")}
        />
        <div className={classes.centerText}>
          <Typography className={classes.cardWelcome}>
            {Languaje.t("MozoBot.continue.welcome")}
          </Typography>
          <Typography className={classes.cardExplication}>
            {Languaje.t("MozoBot.continue.explicaction")}
          </Typography>

          {loadingData ? (
            <Loader
              useAlternativeColor={true}
            ></Loader>
          ) : (
            <>
              <div className={classes.infoArea}>
                <div className={classes.infoUser}>
                  <div
                    className={classes.avatarRounder}
                    data-initial={Helper.CalculateInitials(_.get(user, "name"))}
                  >
                    <ImageFadeIn
                      src={_.get(user, "profile_pic")}
                      onError={(e) => {
                        if (e.target.src !== _.get(user, "profile_pic")) {
                          e.target.src = _.get(user, "profile_pic");
                        }
                      }}
                    />
                  </div>
                  <div className={classes.user}>
                    <span className={classes.markText}>{_.get(user, "name")}</span>
                    <span className={classes.normalText}>
                      {_.get(user, "email")}
                    </span>
                  </div>
                </div>
              </div>
              <MaterialButton
                variant="contained"
                className={classes.btnNormal}
                onClick={handleContinueLogin}
              >
                {Languaje.t("MozoBot.continue.sameuser")}
              </MaterialButton>
              <a className={classes.otheAcount} onClick={handleUseAnotherAccount}>
                {Languaje.t("MozoBot.continue.otherAcount")}
              </a>
            </>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Continue;
