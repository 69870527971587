import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import services from "./../services";
import { PAGES } from "./../utils/constants";
import classes from "./Containers.module.scss";
const Code = (props) => {
    const history = useHistory();
    const params = useParams();
    
    const dispatch = useDispatch();
    
    const scanQr  = async()=>{
        const qr = params.qr;
        try {
            await services.loginInvite({ qr_uuid: qr }, dispatch);
            await services.getCategoriesAndContainers(null, dispatch);
            history.push(PAGES.PRODUCTS)
        } catch (e) {
            console.log("Error",e);
        }
    }
    useEffect( () => {
        scanQr();
    }, [])

    return (
        
        <div className={classes.Preload}><div className={classes.preloaderContent}></div></div>
    );
}

export default Code;

