import { DevTool } from "@hookform/devtools";
import React from "react";
import { useFormContext } from "react-hook-form";
const DevtoolForm = ({ placement = 'top-left' }) => {
    const { control } = useFormContext()
    return (
        <React.Fragment>
            <DevTool control={control} placement={placement} />
        </React.Fragment>
    )
}

export default DevtoolForm
