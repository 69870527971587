import _ from "lodash";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import TextInput from "../TextInput";


const PlacesAutoComplete = ({
  onChange,
  value,
  ...props
}) => {
  

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCcT_OLgXjVs7iJ0-KBm7ZLxZb2g65zBpA",
    debounce: 1000,
    options: {
      types:[]
    },
    onPlaceSelected: (place) => {
      let e = {};
      _.set(e,"target.value",place);
      onChange && onChange(e)
    }
  })
  const handleOnChange = ()=>{
    onChange && onChange("");
  }
  return (
    <div className="search-location-input">
      <TextInput
        inputRef={ref}
        onChange={handleOnChange}
        defaultValue={_.get(value, "formatted_address")}
        {...props}

      />
    </div>
  );
}

export default PlacesAutoComplete;