import { Fab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
//import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import IcomoonReact from "icomoon-react";
import _ from "lodash";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import ImageFadeIn from "react-image-fade-in";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { BtnMore } from "../../components";
//import iconSet from "../../utils/fonts/mozoBot/selection.json";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import ServerConnect from "../../utils/ServerConnect";
import services from "./../../services";
import classes from "./Header.module.scss";
// import { Button as MaterialButton } from "@material-ui/core";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Alert, AlertTitle } from "@material-ui/lab";

class Header extends Component {
  state = {
    localPhoto: ServerConnect.getUrlImage(
      _.get(this.props, "client.uuid_image"),
      "banner",
      {}
    ),
    localName: _.get(this.props, "client.name"),
    mnuModalOpen: false,
    showWarning: false,
  };

  goBackFunction = () => {
    this.props.history.push(
      Helper.generateUrl(this.props.back.path, this.props.back.params)
    );
  };

  handleMnuModal = () => {
    this.setState({
      mnuModalOpen: !this.state.mnuModalOpen,
    });
  };

  render() {

    const { back } = this.props;

    return (
      <React.Fragment>
        {this.state.showWarning && (
          <Alert severity="warning" className={classes.useFont}>
            <AlertTitle className={classes.useFont}>
              {Languaje.t("MozoBot.DeliveryData.warningTitle")}
            </AlertTitle>
            {Languaje.t("MozoBot.takeTable.warning")}
          </Alert>
        )}
        {this.state.localPhoto != null && back == null ? (
          <div>
            <AppBar
              position="static"
              className={classes.newHeaderLong}
              color="transparent"
              elevation={0}
            >
              <div
                className={classes.headerInicials}
                data-initial={Helper.CalculateInitials(
                  _.get(this.props, "client.name")
                )}
              >
                <Toolbar
                  disableGutters={true}
                  className={classes.specialToolbarImage}
                >
                  <section className={classes.navbarSection}>
                    {back && (
                      <Fab
                        size="small"
                        className={classes.btnRounderFlat}
                        onClick={this.goBackFunction}
                      >
                        <ArrowBackIcon />
                      </Fab>
                    )}
                  </section>
                  <section className={classes.navbarCenter}></section>
                  <section className={classes.navbarSection}>
                    {/* <BtnMore btnIconColor="#ffffff" callBack={this.goBackFunction}></BtnMore> */}
                  </section>
                </Toolbar>
                <ImageFadeIn
                  src={ServerConnect.getUrlImage(
                    _.get(this.props, "client.uuid_image"),
                    "banner",
                    {}
                  )}
                  alt={_.get(this.props, "client.name")}
                  onError={(e) => {
                    
                  }}
                />
              </div>
            </AppBar>
            <div className={classes.cardAvatar}>
              <div
                className={classes.avatarRounder}
                data-initial={Helper.CalculateInitials(
                  _.get(this.props, "client.name")
                )}
              >
                {_.get(this.props, "client.logo") != null && (
                  <img
                    src={ServerConnect.getUrlImage(
                      _.get(this.props, "client.logo"),
                      "logo"
                    )}
                    alt={_.get(this.props, "client.name")}
                    onError={(e) => {
                      
                    }}
                  />
                )}
              </div>
            </div>
            <h3 className={classes.nameArea}>
              {_.get(this.props, "client.name")}
            </h3>
          </div>
        ) : (
          <AppBar
            position="static"
            className={isMobile ? classes.newHeader : classes.newHeaderWeb}
          >
            <Toolbar disableGutters={true} className={classes.specialToolbar}>
              <section className={classes.navbarSection}>
                {back && (
                  <Fab
                    size="small"
                    className={classes.btnRounderFlat}
                    onClick={this.goBackFunction}
                  >
                    <ArrowBackIcon />
                  </Fab>
                )}
              </section>
              <section className={classes.navbarCenter}>
                <ImageFadeIn
                  src={`${window.location.origin}/img/l1.png`}
                  alt={Languaje.t("title.app")}
                  className={classes.toolbarLogo}
                />
              </section>
              <section className={classes.navbarSection}>
                {/* <BtnMore btnIconColor="#fff" callBack={this.goBackFunction}></BtnMore> */}
              </section>
            </Toolbar>
          </AppBar>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  qr: state.qr,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (qr) => services.logout(qr, dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
