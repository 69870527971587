import React from "react";
import classes from "./CongratulationsOrderFinish.module.scss";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import ApngComponent from "react-apng";
import Languaje from "../../utils/Languaje";
import ButtonFlat from "@material-ui/core/Button";

const CongratulationsOrderFinish = ({ prevPanel, nextPanel, close }) => {

  return (
    <React.Fragment>
      <div className={classes.allContainer}>
        <div className={`${classes.infoContainer}`}>
          <Card className={classes.transformCardSpecial}>
            <div className={classes.limitSpace}>
              <ApngComponent
                autoPlay={true}
                src={`${window.location.origin}/img/MozoCocina.png`}
              />
            </div>
            <div className={classes.centerText}>
              <Typography className={classes.cardTitleTextWarning}>
                {Languaje.t("MozoBot.order.finish.title")}
              </Typography>
              <Typography className={classes.cardDescriptionWarning}>
                {Languaje.t("MozoBot.order.finish.subtitle")}
              </Typography>
            </div>
          </Card>
        </div>
        <div className={classes.bottomContainer}>
          <ButtonFlat
            variant="contained"
            color="primary"
            className={classes.btnFlat}
            onClick={close}
          >
            {Languaje.t("MozoBot.order.finish.goMenu")}
          </ButtonFlat>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CongratulationsOrderFinish;
