import localstore from "./localstore";
const initialState = localstore.getItem("qr");
const apiReducer = (state = initialState, action) => {
	
	switch (action.type) {
		case 'SET_QR': {
			const   qr = action.payload;
			localstore.setItem("qr", qr) 
			return qr;
		}
		default:
			return state;
	}
};

export default apiReducer;
