import ButtonFlat from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import _ from "lodash";
import { withSnackbar } from 'notistack';
import React, { Component } from "react";
import ImageFadeIn from "react-image-fade-in";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Loader } from "../../components";
import { ACTIONS, PAGES, QR_TYPES } from "../../utils/constants";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import ManagerAccess from './../../components/Managers/ManagerAccess/ManagerAccess';
import Scrollbar from "./../../components/Scrollbar/Scrollbar";
import { setRedirectPage } from "./../../redux/actions/navigation";
import { addOrderLine } from "./../../redux/actions/orders";
import services from "./../../services";
import classes from "./Product.module.scss";
class Product extends Component {
  state = {
    cant: 1,
    notes: "",
    isLoading: false,
    open: false,
  };

  componentWillUpdate(nextProp) {
    if (this.props.product !== nextProp.product) {
      this.updateState(nextProp);
    }
  }

  componentWillMount() {

    this.updateState(this.props);
  }
  getProductId() {
    return _.get(this.props, "match.params.product_id");
  }
  updateState = (props) => {

    const product = this.props.product;
    const { orderLines } = props;
    const orderLineEdit = _.find(orderLines, (orderLine, i) => {
      return orderLine.product_id == product.id;
    });
    if (orderLineEdit) {
      this.setState({
        cant: orderLineEdit.cant,
        notes: orderLineEdit.notes,
      });
    } else {
      this.setState({
        cant: 1,
        notes: "",
        product
      });
    }
  };

  getPrice = (cant, product) => {
    return product.price * cant;
  };
  addAndFinishCart = () => {
    const { product } = this.props;
    const { cant, notes } = this.state;
    this.props.addProduct(product, cant, notes,this.props.qr); 
    this.props.goToCart();
  };

  addProductTable = async () => {
    var { cant, notes, product } = this.state;
    var userStatus = this.props.user.type;
    if (userStatus === "ANONIMOUS") {
      
      this.props.setRedirectPage(PAGES.PRODUCTS, { action:"openProduct", params:{product: product} });
      this.props.history.push(Helper.generateUrl(PAGES.LOGIN));
      return;
    }
    var newProduct = {
      product_id: product.id,
      cant: cant,
      notes: notes,
    };
    if (this.props.qr.codeType == QR_TYPES.TABLES){
      this.setState({
        isLoading: true,
      });
      await this.props.addProductDispatch(newProduct, {
        snakbar: this.props.enqueueSnackbar,
        successMessage: Languaje.t("MozoBot.products.snackAddProductSuccess"),
        errorMessage: Languaje.t("MozoBot.products.snackAddProductError"),
        history: this.props.history
      },this.props.qr,this.props.history);
      
    }else{
      newProduct.name = product.name;
      newProduct.price =  product.price;
      this.props.addOrderLine(newProduct,this.props.client);
      this.props.enqueueSnackbar(Languaje.t("MozoBot.products.snackAddProductSuccess"), {variant: "success"});
    }
    this.props.close();
    
    
  };

  increment = (increment) => {
    let { cant } = this.state;
    cant += increment;
    if (cant < 1) {
      cant = 1;
    }
    this.setState({
      cant,
    });
  };

  onChangeNote = (e) => {
    const notes = e.target.value;
    this.setState({
      notes,
    });
  };

  render() {

    const { imagesProducts } = this.props.client;
    const { product } = this.props;
    const { cant } = this.state;

    return (
      <React.Fragment>
        {product && (
          <React.Fragment>
            <div className={classes.allContainer}>
              <div className={classes.infoContainer}>
                <Scrollbar>
                  <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.transformCard}>
                      {imagesProducts[product.id] && (
                        <div className={classes.productCenter}>
                          <div
                            className={classes.avatarRounder}
                            data-initial={Helper.CalculateInitials(product.name)}
                          >
                            <ImageFadeIn
                              src={imagesProducts[product.id]}
                              alt={product.name}
                              onError={(e) => {
                                
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <CardContent
                        className={`${imagesProducts[product.id]
                          ? classes.specialContent
                          : classes.specialContent2
                          }`}
                      >
                        <Typography className={classes.cardTitleText}>
                          {product.name}
                        </Typography>
                        <Typography className={classes.cardDescription}>
                          {product.description}
                        </Typography>
                        <Typography className={classes.cardIndividualPrice}>
                          {Languaje.t("product.price")}:{" "}
                          {Helper.CurrencyFormat(product.price)}{" "}
                          {Languaje.t("product.unitary")}
                        </Typography>
                      </CardContent>
                      {this.state.isLoading ? (
                        <div>
                          {cant.length > 1 ? (
                            <Loader
                              waitText={Languaje.t("MozoBot.products.addLoading1")+" "+cant+" "+Languaje.t("MozoBot.products.addLoading2")}>
                            </Loader>
                          ) : (
                            <Loader
                              waitText={Languaje.t("MozoBot.products.addLoading1")+" "+cant+" "+Languaje.t("MozoBot.products.addLoading3")}>
                            </Loader>
                          )}
                        </div>
                      ) : (
                          <React.Fragment>
                            <ManagerAccess action={ACTIONS.ADD_PRODUCT}>
                              <CardContent className={classes.specialContent3}>
                                <div className={classes.cardComentary}>
                                  <TextField
                                    label={Languaje.t("title.comentary")}
                                    placeholder={Languaje.t("product.notes.placeholder")}
                                    multiline
                                    className={classes.textField}
                                    margin="normal"
                                    variant="filled"
                                    onChange={this.onChangeNote}
                                    value={this.state.notes}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </div>
                              </CardContent>
                            </ManagerAccess>
                          </React.Fragment>
                        )}
                    </Card>
                  </Grid>
                </Scrollbar>
              </div>
              {!this.state.isLoading && (
                <ManagerAccess action={ACTIONS.ADD_PRODUCT}>
                  <div className={classes.bottomContainer}>
                    <div className={classes.spaceBtnPlus}>
                      <Fab
                        size="small"
                        className={classes.btnRounderFlat}
                        onClick={() => {
                          this.increment(-1);
                        }}
                      >
                        <RemoveIcon />
                      </Fab>
                    </div>
                    &nbsp;
                    <div className={classes.zoneCant}>{cant}</div>
                    &nbsp;
                    <div>
                      <Fab
                        size="small"
                        className={classes.btnRounderFlat}
                        onClick={() => {
                          this.increment(1);
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </div>
                    <ButtonFlat
                      variant="contained"
                      color="primary"
                      className={classes.btnFlat}
                      onClick={this.addProductTable}
                    >
                      {Languaje.t("product.button.add.product")}&nbsp;&nbsp;{Helper.CurrencyFormat(this.getPrice(cant, product))}
                    </ButtonFlat>
                  </div>
                </ManagerAccess>
              )}
              <ManagerAccess action={ACTIONS.LOGIN}>
                {!this.state.isLoading && (
                  <div className={classes.bottomContainer}>
                    <ButtonFlat
                      variant="contained"
                      color="primary"
                      className={classes.btnFlat}
                      onClick={this.addProductTable}
                    >
                      {Languaje.t("product.button.add.product")}
                    </ButtonFlat>
                  </div>
                )}
              </ManagerAccess>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  user: state.user,
  qr: state.qr,
  orders: state.orders,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectPage: (path, params) => {
      dispatch(setRedirectPage(path, params))
    },
    addOrderLine: (orderLine, client) => {
      dispatch(addOrderLine(orderLine, client))
    },
    addProductDispatch:async (product,config,qr,history)=>{
      await services.addProduct(product,config,qr,history,dispatch);
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Product)));
