
import localstore from "./localstore";
import _ from "lodash";
const initialState = {
	token:_.get(localstore.getItem("session"),"token"),
	access:_.get(localstore.getItem("session"),"access"),
	accessToken:_.get(localstore.getItem("session"),"accessToken"),
}


const apiReducer = (state = initialState, action) => {
	
	switch (action.type) {
		case 'SET_TOKEN': {
			const  token  = action.payload;
			const newState = { ...state,  token };
			localstore.setItem("session", newState) 
			return newState;
		}
		case 'SET_ACCESS': {
			const  access  = action.payload;
			const newState = { ...state,  access };
			localstore.setItem("session", newState) 
			return newState;
		}
		case 'SET_ACCESS_TOKEN': {
			const  accessToken  = action.payload;
			const newState = { ...state,  accessToken };
			localstore.setItem("session", newState) 
			return newState;
		}
		
		
		default:
			return state;
	}
};

export default apiReducer;
