import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { QR_TYPES } from "../utils/constants";
import useService from "./../hooks/useService";
import { removeOrderLine as removeOrderLineActions, updateCantOrderLine as updateCantOrderLineActions } from "./../redux/actions/orders";
import services from "./../services";
export default function useOrders(promise, { defaultValue, ...props } = {}) {
    const [getMyOrder] = useService(services.getMyOrder, { defaultValue: [] });
    const client = useSelector(store=> store.client);
    const qr = useSelector(store=> store.qr);
    const dispatch = useDispatch();
    const orders = useSelector(store=> store.orders);
    const getOrders = async ()=>{
        if (qr.codeType === QR_TYPES.TABLES) return await getMyOrder();
        return _.get(orders,`${client.id}`,[]);
    }
    const isDelivery  = ()=>{
        return qr.codeType == "delivery"
    }
    const removeOrderLine  = (index)=>{
        dispatch(removeOrderLineActions(index,client))
    }
    const updateCantOrderLine  = (index,cant)=>{
        dispatch(updateCantOrderLineActions(index,cant,client))
    }
    return {
        ordersMemory:_.get(orders,`${client.id}`,[]),
        getOrders,
        loading: false,
        isDelivery,
        removeOrderLine,
        updateCantOrderLine
    } 
}