
export const setToken = (token) => {
  return (dispatch) => { 
      dispatch({ type: 'SET_TOKEN', payload: token })
  }
}
export const setAccessToken = (accessToken)=>{
  return (dispatch) => { 
    dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken })
}
}
export const setAccess = (access) => {
  return (dispatch) => { 
      dispatch({ type: 'SET_ACCESS', payload: access })
  }
}
export default {
  setToken,
  setAccess,
  setAccessToken
};

