import Container from '@material-ui/core/Container';
import React, { Component } from "react";


class Content extends Component {
    state = {

    };


    render() {

        return (
            <Container maxWidth="sm">
                {this.props.children}
            </Container>
        );
    }
}

export default (Content)
