import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import user from "./reducers/user";
import session from "./reducers/session";
import navigation from "./reducers/navigation";
import client from "./reducers/client";
import qr from "./reducers/qr";
import orders from "./reducers/orders";
const RootReducer = combineReducers({
  user,
  client,
  session,
  qr,
  navigation,
  orders
});



const initialState = {}


const middlewares = [thunk];

const store = createStore(
  RootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ ?
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    ) : compose(applyMiddleware(...middlewares))
);

export const Store = store;