

export const setRedirectPage = (path,params) => {
  return (dispatch) => { 
      dispatch({ type: "SET_REDIRECT_PAGE", path,params })
  }
}
export const clearRedrirectPage = () => {
  return (dispatch) => { 
      dispatch({ type: "CLEAR_REDIRECT_PAGE" })
  }
}


export default {
  setRedirectPage,
  clearRedrirectPage
};

