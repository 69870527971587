import ButtonFlat from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { Component } from "react";
import { PAYMENT_METHODS } from "../../utils/constants";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import classes from "./Cart.module.scss";


class Cart extends Component {
  state = {};

  componentWillMount() {}

  render() {
    const { orderLines, products, imagesProducts } = this.props;
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {orderLines.map((orderLine, i) => {
            const product = _.find(products.rows, (product) => {
              return product.id == orderLine.product_id;
            });
            if (!product) {
              return null;
            }
            return (
              <Grid item xs={12} sm={12} md={4} key={i}>
                <Card
                  className={
                    i == 0
                      ? classes.transformCardSpecial
                      : classes.transformCard
                  }
                >
                  <CardContent className={classes.specialContent}>
                    <div className={classes.cardTitleWapper}>
                      <div className={classes.cardTitle}>
                        <Typography
                          variant="h6"
                          component="h3"
                          className={classes.cardTitleText}
                        >
                          <span className={classes.cantColor}>
                            {orderLine.cant}
                          </span>
                          <span className={classes.xColor}>
                            &nbsp; x &nbsp;
                          </span>
                          {product.name}
                        </Typography>
                        <div className={classes.cardDescription}>
                          {product.description}
                        </div>
                      </div>
                      {imagesProducts[product.id] ? (
                        <div className={classes.cardAvatar}>
                          <div
                            className={classes.avatarRounder}
                            data-initial={Helper.CalculateInitials(
                              product.name
                            )}
                          >
                            <img
                              src={imagesProducts[product.id]}
                              alt={product.name}
                              onError={(e) => {
                                
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </CardContent>
                  {orderLine.notes ? (
                    <CardActions className={classes.cardActionsNotes}>
                      <div className={classes.cardNotes}>
                        <span className={classes.comentaryText}>
                          {Languaje.t("title.comentary")}:
                        </span>
                        &nbsp;
                        {orderLine.notes}
                      </div>
                    </CardActions>
                  ) : null}
                  <CardActions className={classes.cardActionsBtns}>
                    <ButtonFlat
                      variant="contained"
                      className={classes.btnFlat}
                      onClick={() => {
                        this.props.goToProduct(product);
                      }}
                    >
                      {Languaje.t("product.button.edit")}
                    </ButtonFlat>
                    <ButtonFlat
                      variant="contained"
                      className={classes.btnFlat}
                      onClick={() => {
                        this.props.deleteProduct(product);
                      }}
                    >
                      {Languaje.t("product.button.delete")}
                    </ButtonFlat>
                    <Typography
                      variant="subtitle2"
                      className={classes.currencyColor}
                      component="p"
                    >
                      {Helper.CurrencyFormat(orderLine.total_price_calc)}
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <div className={classes.cardTotal}>
          <div className={classes.cardTotalFooter}>
            <section className={classes.footerSection}></section>
            <section className={classes.footerCenter}>
              {Languaje.t("MozoBot.TotalToPay")}:{" "}
              {Helper.CalculateTotals(orderLines)}
            </section>
            <section className={classes.footerSection}></section>
          </div>
        </div>
        <ButtonFlat
          variant="contained"
          color="primary"
          className={classes.btnPay}
          onClick={(e) => {
            this.props.confirmOrder(PAYMENT_METHODS.CASH);
          }}
        >
          {Languaje.t("MozoBot.button.cash.pay")}
        </ButtonFlat>

        <ButtonFlat
          variant="contained"
          color="primary"
          className={classes.btnPay}
          onClick={(e) => {
            this.props.confirmOrder(PAYMENT_METHODS.MERCADOPAGO);
          }}
        >
          {Languaje.t("MozoBot.button.mercadopago.pay")}
        </ButtonFlat>

        {/* <div className={classes.fabAddPrice} onClick={this.props.confirmOrder}>
              {Helper.CalculateTotals(orderLines)}
          </div>
          <Fab className={classes.fabBtn} onClick={this.props.confirmOrder}>
              <CheckIcon className={classes.iconBig}/>
          </Fab> */}
      </React.Fragment>
    );
  }
}

export default Cart;