import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import dotEnv from 'dotenv';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import logger from 'redux-logger'
import "./index.module.scss";
import "./style.scss";


// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=d71a36&secondary.color=D32F2F
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff5a60',
      main: '#d71a36',
      dark: '#9e0011',
      contrastText: '#fff',
    },
  },
});

//Solunika Banner everywhere
const _0x4f78=['color:#0F669D;font-weight:bold;','\x0a\x09%cDeveloped\x20and\x20powered\x20by\x0a\x09%c\x20\x20____\x20\x20\x20\x20\x20\x20\x20\x20_\x20\x20\x20__\x20\x20\x20\x20\x20\x20\x20\x20_\x20_\x20\x20\x20\x20\x20\x20\x20\x20\x20\x0a\x09%c\x20/\x20___|\x20\x20___\x20|\x20|_/_/_\x20_\x20__\x20(_)\x20|\x20____\x20_\x20\x0a\x09%c\x20\x5c___\x20\x5c\x20/\x20_\x20\x5c|\x20|\x20|\x20|\x20|\x20\x27_\x20\x5c|\x20|\x20|/\x20/\x20_\x5c`|\x0a\x09%c\x20\x20___)\x20|\x20(_)\x20|\x20|\x20|_|\x20|\x20|\x20|\x20|\x20|\x20\x20\x20<\x20(_|\x20|\x0a\x09%c\x20|____/\x20\x5c___/|_|\x5c__,_|_|\x20|_|_|_|\x5c_\x5c__,_|\x0a\x09%c\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20www.solunika.com\x20\x20\x20\x20\x20\x20\x20','log','color:#DF013A;font-weight:bold;','userAgent'];(function(_0x5a9afc,_0x3ed096){const _0x4f781f=function(_0x3889f4){while(--_0x3889f4){_0x5a9afc['push'](_0x5a9afc['shift']());}};_0x4f781f(++_0x3ed096);}(_0x4f78,0x116));const _0x3889=function(_0x5a9afc,_0x3ed096){_0x5a9afc=_0x5a9afc-0x10a;let _0x4f781f=_0x4f78[_0x5a9afc];return _0x4f781f;};const _0x43c3f5=_0x3889;let g=_0x43c3f5(0x10a),b=_0x43c3f5(0x10c),u=_0x43c3f5(0x10c),isChromeFriendly=/Chrome/['test'](navigator[_0x43c3f5(0x10b)])&&/Google Inc/['test'](navigator['vendor']),textChrome=_0x43c3f5(0x10d);isChromeFriendly&&console[_0x43c3f5(0x10e)](textChrome,g,b,b,b,b,b,u);
//Solunika Banner everywhere

dotEnv.config({path: './.env'});
/* Inits Sentry */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  tracesSampleRate: 1.0,
});
/* Inits Sentry */

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);


