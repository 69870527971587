import ButtonFlat from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar/Scrollbar";
import useData from "../../hooks/useData";
import useOrders from "../../hooks/useOrders";
import useQr from "../../hooks/useQr";
import services from "../../services";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import classes from "./ResumenOrder.module.scss";

const ResumenOrder = ({ prevPanel, nextPanel }) => {

  const history = useHistory();
  const { data } = useData();
  const dispatch = useDispatch();
  const [orderLines, setOrderLines] = useState([]);
  const { getOrders, loading } = useOrders();
  const {isDelivery,qr} = useQr()
  const client = useSelector((store) => _.get(store, "client"));
  const { deliveryCost, selectDelivery } = useMemo(() => {
    const deliveryCost = _.get(client, "config.deliveryCost", null);
    return { selectDelivery: isDelivery() && !!deliveryCost, deliveryCost };
  }, [JSON.stringify(client)]);

  const setInitialData = async () => {
    const orders = await getOrders();
    setOrderLines(orders);
  };

  useEffect(() => {
    setInitialData();
  }, []);

  const handleMakeAnOrder = async () => {
    const values = data.delivery;
    const orderLinesServer = _.map(orderLines, (o) => {
      return {
        product_id: o.product_id,
        cant: o.cant,
        notes: o.notes,
      };
    });
    await services.saveOrderDelivery(qr,
      { orderLines: orderLinesServer, ...values },
      history,
      isDelivery(),
      dispatch
    );
    nextPanel();
  };

  const floor = useMemo(() => {
    return _.get(data, "delivery.aditional_address", false);
  }, [JSON.stringify(data)]);

  return (
    <React.Fragment>
      <div className={classes.allContainer}>
        <div className={classes.topContainer}>
          <h2 className={classes.titleCat}>
            {Languaje.t("MozoBot.order.summary")}
          </h2>
        </div>
        <div className={`${classes.infoContainer}`}>
          <Scrollbar>
            {orderLines.map((orderLine, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <Card className={classes.transformCard}>
                    <CardContent className={classes.specialContent}>
                      <div className={classes.cardTitleWapper}>
                        <div>
                          <Typography
                            variant="h6"
                            component="h3"
                            className={classes.cardTitleText}
                          >
                            <span className={classes.cantColor}>
                              {orderLine.cant}
                            </span>
                            <span className={classes.xColor}>
                              &nbsp;x&nbsp;
                            </span>
                            {orderLine.name}
                          </Typography>
                          {orderLine.notes !== "Sin notas" &&
                            orderLine.notes.length > 1 && (
                              <CardActions className={classes.cardActionsNotes}>
                                <div className={classes.cardNotes}>
                                  <span className={classes.comentaryText}>
                                    {Languaje.t("title.comentary")}:
                                  </span>
                                  &nbsp;
                                  {orderLine.notes}
                                </div>
                              </CardActions>
                            )}
                        </div>
                        <Typography
                          variant="subtitle2"
                          className={classes.currencyColor}
                          component="p"
                        >
                          {Helper.CurrencyFormat(
                            orderLine.price * orderLine.cant
                          )}
                        </Typography>
                      </div>
                      <li className={classes.divider}></li>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
            {selectDelivery && (
              <Grid item xs={12}>
                <Card className={classes.transformCard}>
                  <CardContent className={classes.specialContent}>
                    <div className={classes.cardTitleWapper}>
                      <div>
                        <Typography
                          variant="h6"
                          component="h3"
                          className={classes.cardTitleText}
                        >
                          {Languaje.t("MozoBot.order.delivery.service.price")}
                        </Typography>
                      </div>
                      <Typography
                        variant="subtitle2"
                        className={classes.currencyColor}
                        component="p"
                      >
                        {Helper.CurrencyFormat(deliveryCost)}
                      </Typography>
                    </div>
                    <li className={classes.divider}></li>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item xs={12}>
              <Card className={classes.transformCard}>
                <CardContent className={classes.specialContent}>
                  <div className={classes.cardTitleWapper}>
                    <div>
                      <Typography
                        variant="h6"
                        component="h3"
                        className={classes.cardTitleTextPrice}
                      >
                        {Languaje.t("MozoBot.order.total.price")}
                      </Typography>
                    </div>
                    <Typography
                      variant="subtitle2"
                      className={classes.currencyColor2}
                      component="p"
                    >
                      {Helper.CalculateTotals(
                        orderLines,
                        selectDelivery ? deliveryCost : 0
                      )}
                    </Typography>
                  </div>
                  <li className={classes.divider}></li>
                </CardContent>
              </Card>
            </Grid>
            <div className={classes.formMargins}>
              <Typography
                variant="subtitle2"
                className={classes.specialsubtitle}
                component="p"
              >
                {Languaje.t(
                  selectDelivery
                    ? "MozoBot.order.title.deliveryData"
                    : "MozoBot.order.title.takeOrder"
                )}
              </Typography>
            </div>
            {selectDelivery ? (
              <Typography
                variant="subtitle2"
                className={classes.cardTitleText2}
                component="p"
              >
                {Languaje.t("MozoBot.order.delivery.part1")}
                <span className={classes.dataColor}>
                  {_.get(data, "delivery.address.formatted_address")}
                </span>
                <br></br>
                {floor && (
                  <>
                    {Languaje.t("MozoBot.order.delivery.floor")}
                    <span className={classes.dataColor}>
                      {_.get(data, "delivery.aditional_address")}
                    </span>
                    <br></br>
                  </>
                )}
                {Languaje.t("MozoBot.order.delivery.part2")}
                <span className={classes.dataColor}>
                  {_.get(data, "delivery.name")}
                </span>
                <br></br>
                {Languaje.t("MozoBot.order.delivery.part3")}
                <span className={classes.dataColor}>
                  {_.get(data, "delivery.phone")}
                </span>
                <br></br>
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                className={classes.cardTitleText2}
                component="p"
              >
                {Languaje.t("MozoBot.order.delivery.part4")}
                <span className={classes.dataColor}>{client.address}</span>
                <br></br>
                {Languaje.t("MozoBot.order.delivery.part5")}
                <span className={classes.dataColor}>
                  {_.get(data, "delivery.name")}
                </span>
                <br></br>
              </Typography>
            )}
            <Typography
              variant="subtitle2"
              className={classes.cardTitleText2}
              component="p"
            >
              {Languaje.t("MozoBot.order.delivery.payment")}
              <span className={classes.dataColor}>TODO: PRINT PAGO</span>
            </Typography>
            {_.get(data, "delivery.comments") && (
              <Typography
                variant="subtitle2"
                className={classes.cardTitleText2}
                component="p"
              >
                {Languaje.t("MozoBot.order.delivery.comment")}:<br />
                <span className={classes.dataColor}>
                  {_.get(data, "delivery.comments")}
                </span>
              </Typography>
            )}
          </Scrollbar>
        </div>
        <div className={classes.bottomContainer}>
          <ButtonFlat
            variant="contained"
            color="primary"
            className={classes.btnFlat}
            onClick={prevPanel}
          >
            {Languaje.t("product.button.previous.step")}
          </ButtonFlat>
          <ButtonFlat
            variant="contained"
            color="primary"
            className={classes.btnFlat}
            onClick={handleMakeAnOrder}
          >
            {Languaje.t("MozoBot.order.summary.btnNext")}
          </ButtonFlat>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumenOrder;
