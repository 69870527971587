import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import classes from "./Mensajes.module.scss";
import Languaje from "../../utils/Languaje";
import CardActions from "@material-ui/core/CardActions";
import ButtonFlat from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ImageFadeIn from "react-image-fade-in";
import ApngComponent from 'react-apng';

class Mensajes extends Component {
  state = {};

  render() {
    const { title, subtitle, btntext, status } = this.props;
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={12}>
            <Card className={classes.transformCard}>
              {status == "error" ? (
                <div className={classes.limitSpace}>
                  <ApngComponent autoPlay={true} src={`${window.location.origin}/img/MozoError.png`} />
                </div>
              ) : null}
              {status == "warning" ? (
                <ImageFadeIn
                  className={classes.cardImgTop}
                  src={`${window.location.origin}/img/MozoWarning.svg`}
                  alt={Languaje.t(title)}
                />
              ) : null}
              {status == "success" ? (
                <ImageFadeIn
                  className={classes.cardImgTop}
                  src={`${window.location.origin}/img/MozoSuccess.svg`}
                  alt={Languaje.t(title)}
                />
              ) : null}
              <div className={classes.centerText}>
                <Typography className={classes.cardTitleText}>
                  {Languaje.t(title)}
                </Typography>
                <Typography className={classes.cardDescription}>
                  {Languaje.t(subtitle)}
                </Typography>
              </div>
              <CardActions className={classes.cardActionsBtns}>
                {btntext ? (
                  <ButtonFlat
                    variant="contained"
                    color="primary"
                    className={classes.btnFlat}
                    onClick={this.props.btnAction}
                  >
                    {Languaje.t(btntext)}
                  </ButtonFlat>
                ) : null}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Mensajes;