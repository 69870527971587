import { Fab, Fade } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
//import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SearchIcon from "@material-ui/icons/Search";
//import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import _ from "lodash";
import { withSnackbar } from 'notistack';
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import ReactGA from "react-ga";
import ImageFadeIn from "react-image-fade-in";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll, Element, scroller } from "react-scroll";
import { FloatPrice, FloatZoomBtn, InfoDrawer, TagFilter } from "../../components";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import ServerConnect from "../../utils/ServerConnect";
import DrawerPage from "./../../components/DrawerPage/DrawerPage";
import ManagerAccess from './../../components/Managers/ManagerAccess/ManagerAccess';
import { DataProvider } from "./../../hooks/useData";
import { clearRedrirectPage, setRedirectPage } from "./../../redux/actions/navigation";
import services from "./../../services";
import { ACTIONS } from "./../../utils/constants";
import Product from "./../Product/Product";
import classes from "./Products.module.scss";
import ProductSlider from "./ProductSlider";

class Products extends Component {

  state = {
    filter: "",
    catShow: true,
    contactWhatsapp: true,
    drawerProduct: false,
    drawerOrder: false,
    product: null,
    index: process.env.REACT_APP_CANT_IMG_LOAD,
    interval: null
  };
  proccessNavigationPending = () => {

  }
  componentDidMount() {
    
    
    const navigationAction = _.get(this.props.navigation, "redirect.params.action")
    const navigationParameters = _.get(this.props.navigation, "redirect.params.params")
    switch (navigationAction) {
      case "callMozo":
        this.props.callMozo(navigationParameters);
        break
      case "callCount":
        this.props.callCount(navigationParameters);
        break
      case "addProduct":
        this.props.addProduct(navigationParameters);
        break
      case "openProduct":
        const prod = _.find(_.get(this.props,"client.products.rows"),(p)=>p.id==_.get(navigationParameters,"product.id"));
        if (prod){
          this.handleOrderWithLogin(prod);
        }
        
        break
      default:
        break;
    }
  }
  componentWillMount() {

    const interval = setInterval(() => {
      if (this.state.index >= this.props.client.products.count) {
        clearInterval(this.state.interval);
      } else {
        this.setState({ index: this.state.index + process.env.REACT_APP_CANT_IMG_LOAD })
      }
    }, process.env.REACT_APP_SECONDS_TO_DELAY_LOAD_IMAGES * 1000);
    this.setState({ interval })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  handleChangeFilter = (e) => {
    this.handleKeyPress(e);
    this.setState({
      filter: e.target.value,
    });
  };

  scrollToTop() {
    ReactGA.event({
      category: 'Usuario',
      action: 'scrollToTop()'
    });
    scroll.scrollToTop({
      duration: 1000,
      delay: 250,
      smooth: true,
    });
  }

  scrollToPosition(theID) {
    scroller.scrollTo(theID, {
      duration: 1000,
      delay: 250,
      smooth: true,
      offset: -6,
    });
  }

  handleKeyPress = (e) => {
    if (e.target.value.length > 0) {
      this.setState({
        catShow: false,
      });
    } else {
      this.setState({
        catShow: true,
      });
    }
  };

  closeDrawerProduct = () => {
    this.setState({ drawerProduct: false })
  }

  closeDrawerOrder = () => {
    this.setState({ drawerOrder: false })
  }

  goToMyOrders = () => {
    ReactGA.event({
      category: 'Usuario',
      action: 'goToMyOrders()'
    });
    this.setState({
      drawerOrder: true
    })
  }

  handleOrderWithLogin = (product) => {
    ReactGA.event({
      category: 'Usuario',
      action: 'handleOrderWithLogin()'
    });
    this.setState({
      product,
      drawerProduct: true
    })
  }

  render() {
    const {
      orderLines,
      imagesProducts,
      productsByCategories,
    } = this.props.client;
    const { filter } = this.state;
    let isEmpty = true;
    let producsFilter = [];
    let indexProduct = {};

    _.forEach(productsByCategories, (cat) => {
      let productFilter = cat.products;
      if (filter != "") {
        productFilter = _.filter(cat.products, (product) => {
          const description = product.description ? product.description : "";
          const name = product.name ? product.name : "";
          return (
            name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
            description.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          );
        });
      }
      producsFilter.push({
        ...cat,
        products: productFilter,
      });
    });

    producsFilter.forEach(function (entry) {
      if (entry.products.length > 0) {
        isEmpty = false;
      }
    });

    return (
      <React.Fragment>
        <div className={classes.searchSpecialMargins}>
          <TextField
            id="outlined-bare"
            className={classes.seachtText}
            onChange={this.handleChangeFilter}
            value={filter}
            margin="normal"
            InputProps={{
              disableUnderline: true,
              "aria-label": "bare",
            }}
            placeholder={Languaje.t("product.input.placeholder.seach")}
          />
          <Fab className={classes.btnRounderFlatSearch}>
            <SearchIcon></SearchIcon>
          </Fab>
        </div>
        {this.state.catShow && (
          isMobile ? (
            <div className={classes.divHorizontalScroll}>
              <div className={classes.leftFade}></div>
              <div className={classes.scrollingWrapper}>
                {producsFilter.map((cat, i) => {
                  return (cat.products.length > 0 && (
                    <React.Fragment>
                      <TagFilter
                        onClick={() => {
                          this.scrollToPosition(cat.id);
                        }}
                      >
                        {Helper.Capitalizer(cat.name, "firstWord")}
                      </TagFilter>
                    </React.Fragment>
                  ));
                })}
              </div>
              <div className={classes.rightFade}></div>
            </div>
          ) : (
            <div className={classes.divHorizontalWeb}>
              {producsFilter.map((cat, i) => {
                return !!cat.products.length && (
                  <TagFilter key={i} onClick={() => this.scrollToPosition(cat.id)}>
                    {Helper.Capitalizer(cat.name, "firstWord")}
                  </TagFilter>
                );
              })}
            </div>
          )
        )}
        {isEmpty ? (
          <Card className={classes.transformCardWarning}>
            <img
              className={classes.cardImgTop}
              src={`${window.location.origin}/img/MozoMenu.svg`}
              alt={Languaje.t("product.input.noResult.title")}
            />
            <div className={classes.centerText}>
              <Typography className={classes.cardTitleText}>
                {Languaje.t("product.input.noResult.title")}
              </Typography>
              <Typography className={classes.cardDescription}>
                {Languaje.t("product.input.noResult.subtitle")}
              </Typography>
            </div>
          </Card>
        ) : (
          producsFilter.map((cat, cat_id) => {
            return (
              <Grid
                key={cat_id}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <React.Fragment>
                  {cat.products.length > 0 && (
                    <React.Fragment>
                      <h2 className={classes.titleCat}>
                        <Fab
                          size="small"
                          className={classes.btnRounderToTop}
                          onClick={this.scrollToTop}
                        >
                          <ExpandLessIcon />
                        </Fab>
                        <Element key={cat.id} name={cat.id.toString()}>
                          {Helper.Capitalizer(cat.name, "firstWord")}
                        </Element>
                      </h2>
                      {cat.products.map((product, prd_id) => {
                        indexProduct[cat_id + "_" + prd_id] = Object.keys(indexProduct).length + 1;
                        return (
                          <Grid item xs={12} sm={12} md={4} key={prd_id}>
                            <Fade in={true}>
                              <Card
                                className={
                                  prd_id == 0
                                    ? classes.transformCardSpecial
                                    : classes.transformCard
                                }
                              >
                                <CardContent className={classes.specialContent} onClick={() => {
                                  this.handleOrderWithLogin(product);
                                }}>
                                  <div className={classes.cardTitleWapper}>
                                    <div className={imagesProducts[product.id] ? classes.cardTitle : classes.cardTitleExtended}>
                                      <Typography
                                        variant="h6"
                                        component="h3"
                                        className={classes.cardTitleText}
                                      >
                                        {product.name}
                                      </Typography>
                                      <div className={classes.cardDescription}>
                                        {product.description}
                                        <div
                                          className={classes.whiteEllipsis}
                                        ></div>
                                      </div>
                                    </div>
                                    {imagesProducts[product.id] ? (
                                      <div className={classes.cardAvatar}>
                                        <div
                                          className={classes.avatarRounder}
                                          data-initial={Helper.CalculateInitials(
                                            product.name
                                          )}
                                        >
                                          {indexProduct[cat_id + "_" + prd_id] <= this.state.index && (
                                            <ImageFadeIn
                                              src={ServerConnect.getUrlImage(product.uuid_image,'thumb',{})}
                                              alt={product.name}
                                              onError={(e) => {
                                                
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </CardContent>
                                <CardActions className={classes.cardActionsBtns}>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.currencyColor}
                                    component="p"
                                  >
                                    {Helper.CurrencyFormat(product.price)}
                                  </Typography>
                                </CardActions>
                              </Card>
                            </Fade>
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Grid>
            );
          })
        )
        }
        <ManagerAccess action={ACTIONS.MENU_DOWN}>
          <FloatZoomBtn
            activatorIcon="mozoBotLogo"
            activatorIconSize="24"
            closeIcon="close"
            closeIconSize="32"
            showPrice={orderLines && orderLines.length > 0}
            priceValue={Helper.CalculateTotals(orderLines)}
            goToMyOrders={this.goToMyOrders}
            handleDrawer={this.props.handleDrawer}
          >
          </FloatZoomBtn>
          <FloatPrice

            onClick={this.goToMyOrders}
          >
          </FloatPrice>
        </ManagerAccess>
        {isMobile ? (
          <>
            <DrawerPage open={this.state.drawerProduct} close={this.closeDrawerProduct}>
              <Product product={this.state.product} close={this.closeDrawerProduct}></Product>
            </DrawerPage>
            <DrawerPage open={this.state.drawerOrder} close={this.closeDrawerOrder}>
              <DataProvider>
                <ProductSlider onClose={this.closeDrawerOrder} />
              </DataProvider>
            </DrawerPage>
          </>
        ) : (
          <>
            <InfoDrawer
              isOpen={this.state.drawerProduct}
              closeDrawer={this.closeDrawerProduct}
            >
              <Product product={this.state.product} close={this.closeDrawerProduct}></Product>
            </InfoDrawer>
            <InfoDrawer
              isOpen={this.state.drawerOrder}
              closeDrawer={this.closeDrawerOrder}
            >
              <DataProvider>
                <ProductSlider onClose={this.closeDrawerOrder} />
              </DataProvider>
            </InfoDrawer>
          </>
        )}
        <div className={classes.spacerBottom}></div>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  client: state.client,
  order: state.orders,
  navigation: state.navigation
});
const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectPage: (path, params) => {
      dispatch(setRedirectPage(path, params))
    },
    callMozo: (navigationParameters) => {
      services.callMozo(navigationParameters.params, navigationParameters.config, navigationParameters.qr, navigationParameters.history, dispatch)
      dispatch(clearRedrirectPage())
    },
    callCount: (navigationParameters) => {
      services.callCount(navigationParameters.params, navigationParameters.config, navigationParameters.qr, navigationParameters.history, dispatch)
      dispatch(clearRedrirectPage())
    },
    addProduct:(navigationParameters) => {
      services.addProduct(navigationParameters.params, navigationParameters.config, navigationParameters.qr, navigationParameters.history, dispatch)
      dispatch(clearRedrirectPage())
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Products)));