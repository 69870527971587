import { Button as MaterialButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import React, { useEffect } from "react";
import ImageFadeIn from "react-image-fade-in";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { clearRedrirectPage } from "../../redux/actions/navigation";
import { PAGES } from "../../utils/constants";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import classes from "./OwnerTaken.module.scss";
const OwnerTaken = ({ imagesProducts }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGoToMenu = () => {
    history.push(Helper.generateUrl(PAGES.PRODUCTS));
  }
  useEffect(()=>{
    dispatch(clearRedrirectPage());
  },[])
  return (
    <React.Fragment>
      {/* <div className={classes.foodBackground}>
        <div className={classes.backgroundOpaque}> */}
          <Card className={classes.transformCardWarning}>
            <ImageFadeIn
              className={classes.cardImgPosition}
              src={`${window.location.origin}/img/mozoapp_2hb.svg`}
              alt={Languaje.t("title.app")}
            />
            <div className={classes.centerText}>
              <Typography className={classes.cardWelcome}>
                {Languaje.t("MozoBot.OwnerTaken.title")}
              </Typography>
              <Typography className={classes.cardExplication}>
                {Languaje.t("MozoBot.OwnerTaken.Explication")}
              </Typography>
              <div className={classes.btnArea}>       
                <MaterialButton
                  variant="contained"
                  className={classes.btnChangeStep}
                  onClick={handleGoToMenu}
                >
                  <NavigateBeforeIcon />
                  {Languaje.t("MozoBot.login.finish.goMenu")}
                </MaterialButton>
              </div>
            </div>
          </Card>
        {/* </div>
      </div> */}
    </React.Fragment>
  );
};

export default OwnerTaken;