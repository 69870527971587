import React, { Component } from "react";
import classes from "./FloatZoomBtn.module.scss"
import Chip from '@material-ui/core/Chip';
import { Fab } from "@material-ui/core";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import IcomoonReact from "icomoon-react";
import ManagerAccess from "../Managers/ManagerAccess/ManagerAccess";
import Languaje from "../../utils/Languaje";
import services from "./../../services";
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { PAGES } from "./../../utils/constants";
import Helper from "../../utils/Helper";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { setRedirectPage } from "./../../redux/actions/navigation"
import _ from "lodash";
import { ACTIONS } from "./../../utils/constants";
import { connect } from "react-redux";
import { ModalWindow } from "../../components";

class FloatZoomBtn extends Component {
  state = {
    zoomBtnShow: false,
    mnuModalOpen: false,
  };

  constructor(props) {
    super();
    this.handleScroll = this.handleScroll.bind(this)
  }
  /* Menu functions */
  callMozo = async () => {
    this.handleBtnZoom();
    var userStatus = _.get(this.props, "user.type");
    if (userStatus === "ANONIMOUS") {
      this.props.setRedirectPage(PAGES.PRODUCTS,
        {
          action: "callMozo", params: {
            params: {}, config: {
              snakbar: this.props.enqueueSnackbar,
              successMessage: Languaje.t("MozoBot.products.FloatZoomBtn.callMozo.snackSuccess"),
              errorMessage: Languaje.t("MozoBot.products.FloatZoomBtn.callMozo.snackError"),
              history: this.props.history
            }, qr: this.props.qr, history: this.props.history
          }
        });
      this.props.history.push(Helper.generateUrl(PAGES.LOGIN));
      return;
    }
    await this.props.callMozo({}, {
      snakbar: this.props.enqueueSnackbar,
      successMessage: Languaje.t("MozoBot.products.FloatZoomBtn.callMozo.snackSuccess"),
      errorMessage: Languaje.t("MozoBot.products.FloatZoomBtn.callMozo.snackError"),
      history: this.props.history
    }, this.props.qr, this.props.history)
  }
  callCount = async () => {
    this.handleBtnZoom();
    var userStatus = _.get(this.props, "user.type", "ANONIMOUS");
    if (userStatus === "ANONIMOUS") {
      this.props.setRedirectPage(PAGES.PRODUCTS,
        {
          action: "callCount", params: {
            params: {}, config: {
              snakbar: this.props.enqueueSnackbar,
              successMessage: Languaje.t("MozoBot.products.FloatZoomBtn.getTotal.snackSuccess"),
              errorMessage: Languaje.t("MozoBot.products.FloatZoomBtn.getTotal.snackError"),
              history: this.props.history
            }, qr: this.props.qr, history: this.props.history
          }
        });
      this.props.history.push(Helper.generateUrl(PAGES.LOGIN));
      return;
    }
    try {
      await this.props.callCount({}, {
        snakbar: this.props.enqueueSnackbar,
        successMessage: Languaje.t("MozoBot.products.FloatZoomBtn.getTotal.snackSuccess"),
        errorMessage: Languaje.t("MozoBot.products.FloatZoomBtn.getTotal.snackError"),
        history: this.props.history
      }, this.props.qr, this.props.history);
    } catch (e) {

    }

  }

  handleGotoMyOrders = () => {
    this.handleBtnZoom();
    // this.props.history.push(PAGES.MYORDERS);
    this.props.goToMyOrders();
  }

  callWhatsapp = () => {
    const whatsapp = _.get(this.props, "client.whatsapp");
    window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`, "_black");
  }

  /* Fin menu functions */
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    var checkStatus = this.state.zoomBtnShow;
    if (checkStatus) {
      checkStatus = false;
    }
    this.setState({
      zoomBtnShow: checkStatus,
    });
  }

  handleBtnZoom() {
    this.setState({
      zoomBtnShow: !this.state.zoomBtnShow,
    });
  }

  handleMnuModal = () => {
    this.setState({
      mnuModalOpen: !this.state.mnuModalOpen,
    });
  };

  render() {
    return (
      <>
        <div className={classes.zoomBtn}>
          {this.props.showPrice ? (
            <div
              className={classes.fabPrice}
            >
              {this.props.priceValue}
            </div>
          ) : null}
          <Fab className={classes.zoomBtnFab}
            onClick={() => {
              this.handleBtnZoom();
            }}
          >
            {this.state.zoomBtnShow ? (
              <IcomoonReact
                iconSet={iconSet}
                color="#ffffff"
                size={this.props.closeIconSize == null ? 32 : this.props.closeIconSize}
                icon={this.props.closeIcon}
              />
            ) : (
              <IcomoonReact
                iconSet={iconSet}
                color="#ffffff"
                size={this.props.activatorIconSize == null ? 32 : this.props.activatorIconSize}
                icon={this.props.activatorIcon}
              />
            )}
          </Fab>
          <ul className={`${classes.zoomBtnMenu} ${this.state.zoomBtnShow ? classes.showZoom : ""}`}>
            <li>
              <ManagerAccess action={ACTIONS.WHATSAPP}>
                {_.get(this.props, "client.whatsapp") && (
                  <div className={classes.zoomBtnMenuItems}>
                    <Chip label={Languaje.t("MozoBot.products.FloatZoomBtn.whatsapp")} className={`${classes.scaleTransition} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} />
                    <Fab className={`${classes.scaleTransition} ${classes.zoomBtnMenuInternalBtnWA} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} onClick={this.callWhatsapp} disabled={!_.get(this.props, "client.whatsapp", false)}>
                      <WhatsAppIcon className={classes.iconWhats} />
                    </Fab>
                  </div>
                )}
              </ManagerAccess>
              <div className={classes.zoomBtnMenuItems}>
                <Chip label={Languaje.t("MozoBot.About2")} className={`${classes.scaleTransition} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} />
                <Fab className={`${classes.scaleTransition} ${classes.zoomBtnMenuInternalBtnLI} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} onClick={this.props.handleDrawer}>
                  <IcomoonReact
                    iconSet={iconSet}
                    color="#ffffff"
                    size={20}
                    icon={"localInfo"}
                  />
                </Fab>
              </div>
              <ManagerAccess action={ACTIONS.MENU_UP}>
                <div className={classes.zoomBtnMenuItems}>
                  <Chip label={Languaje.t("MozoBot.login.show.profile")} className={`${classes.scaleTransition} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} />
                  <Fab className={`${classes.scaleTransition} ${classes.zoomBtnMenuInternalBtn} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} onClick={() => {
                    this.handleMnuModal();
                    this.handleBtnZoom();
                  }}>
                    <IcomoonReact
                      iconSet={iconSet}
                      color="#ffffff"
                      size={22}
                      icon={"user"}
                    />
                  </Fab>
                </div>
              </ManagerAccess>
              <ManagerAccess action={"view_order"}>
                <div className={classes.zoomBtnMenuItems}>
                  <Chip label={Languaje.t("MozoBot.products.FloatZoomBtn.myOrders")} className={`${classes.scaleTransition} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} />
                  <Fab className={`${classes.scaleTransition} ${classes.zoomBtnMenuInternalBtn} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} onClick={this.handleGotoMyOrders}>
                    <IcomoonReact
                      iconSet={iconSet}
                      color="#ffffff"
                      size={20}
                      icon={"list-text"}
                    />
                  </Fab>
                </div>
              </ManagerAccess>
              <ManagerAccess action={"call_count"}>
                <div className={classes.zoomBtnMenuItems}>
                  <Chip label={Languaje.t("MozoBot.products.FloatZoomBtn.getTotal")} className={`${classes.scaleTransition} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} />
                  <Fab className={`${classes.scaleTransition} ${classes.zoomBtnMenuInternalBtn} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} onClick={this.callCount}>
                    <IcomoonReact
                      iconSet={iconSet}
                      color="#ffffff"
                      size={20}
                      icon={"ticket"}
                    />
                  </Fab>
                </div>
              </ManagerAccess>
              <ManagerAccess action={"call_mozo"}>
                <div className={classes.zoomBtnMenuItems}>
                  <Chip label={Languaje.t("MozoBot.products.FloatZoomBtn.callMozo")} className={`${classes.scaleTransition} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} />
                  <Fab className={`${classes.scaleTransition} ${classes.zoomBtnMenuInternalBtn} ${!this.state.zoomBtnShow ? classes.scaleOut : ""}`} onClick={this.callMozo}>
                    <IcomoonReact
                      iconSet={iconSet}
                      color="#ffffff"
                      size={20}
                      icon={"waiter2"}
                    />
                  </Fab>
                </div>
              </ManagerAccess>
            </li>
          </ul>
        </div>
        <ModalWindow
          open={this.state.mnuModalOpen}
          setOpen={this.handleMnuModal}
        >
        </ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  user: state.user,
  qr: state.qr
});
const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectPage: (path, params) => {
      dispatch(setRedirectPage(path, params))
    },
    callMozo: async (params, config, qr, history) => {
      await services.callMozo(params, config, qr, history, dispatch);
    },
    callCount: async (params, config, qr, history) => {
      await services.callCount(params, config, qr, history, dispatch);
    },
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(FloatZoomBtn)));