import React from "react";
import useQr from "../../hooks/useQr";
import SliderHorizontal from "../../modules/core/components/Slider/SliderHorizontal";
import CongratulationsOrderFinish from "../CongratulationsOrderFinish";
import DeliveryData from "../ClientFormData/DeliveryData";
import UniqueData from "../ClientFormData/UniqueData";
import PayData from "../ClientFormData/PayData";
import ResumenOrder from "../ResumenOrder";
import MyOrders from "./../MyOrders/MyOrders";
import MyOrdersTable from "./../MyOrders/MyOrdersTable";

const ProductSlider = ({ onClose }) => {
  const { isDelivery, isUnique, isTable } = useQr();

  return (
    <React.Fragment>
      {isDelivery() && (
        <SliderHorizontal
          panels={[
            MyOrders,
            DeliveryData,
            PayData,
            ResumenOrder,
            CongratulationsOrderFinish,
          ]}
          close={onClose}
        />
      )}
      {isUnique() && (
        <SliderHorizontal
          panels={[
            MyOrders,
            UniqueData,
            PayData,
            ResumenOrder,
            CongratulationsOrderFinish,
          ]}
          close={onClose}
        />
      )}
      {isTable() && (
        <SliderHorizontal panels={[MyOrdersTable]} close={onClose} />
      )}
    </React.Fragment>
  );
};

export default ProductSlider;
