import localstore from "./localstore";
import { CLIENT_TYPE } from "./../../utils/constants";
const initialState = localstore.getItem("user");
const apiReducer = (state = initialState, action) => {
	
	switch (action.type) {
		case 'SET_USER': {
			let  user = action.payload;
			if(user===null){
				user = {type:CLIENT_TYPE.ANONIMOUS}
			}
			localstore.setItem("user", user) 
			return user;
		}
		
		default:
			return state;
	}
};

export default apiReducer;
