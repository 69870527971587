export const addOrderLine = (orderLine,client) => {
  return (dispatch) => { 
      dispatch({ type: "ADD_ORDER_LINE", payload: {orderLine,client} })
  }
}
export const removeOrderLine = (index,client) => {
  return (dispatch) => { 
      dispatch({ type: "REMOVE_ORDER_LINE", payload: {index,client} })
  }
}
export const clearOrder = (client) => {
  return (dispatch) => { 
      dispatch({ type: "CLEAR_ORDER", payload: {client} })
  }
}
export const updateCantOrderLine = (index,cant,client) => {
  return (dispatch) => { 
      dispatch({ type: "UPDATE_CANT_ORDER_LINE", payload: {index,client,cant} })
  }
}


export default {
  clearOrder,
  addOrderLine,
  removeOrderLine,
  updateCantOrderLine
};
