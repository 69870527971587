import { Fab } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import ButtonFlat from "@material-ui/core/Button";
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import IcomoonReact from "icomoon-react";
import _ from "lodash";
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import ImageFadeIn from "react-image-fade-in";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Loader } from "../../components";
import iconSet from "../../utils/fonts/mozoBot/selection.json";
import Helper from "../../utils/Helper";
import Languaje from "../../utils/Languaje";
import services from "./../../services";
import { PAGES } from "./../../utils/constants";
import classes from "./ModalWindow.module.scss";
const theme = createMuiTheme({
  palette: {
    primary: { 500: '#D0051E' },
  },
  typography: {
    fontFamily: [
      'Google Sans',
    ],
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: 18,
      },
    },
  }
});

export default function ModalWindow({open,setOpen,...props}) {
  const history = useHistory();
  const user = useSelector(state => state.user);
  

  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const [checkCode, setCheckCode] = useState(false);
  const [verifyCode, setverifyCode] = useState("");
  const [myphone, setMyPhone] = useState(_.get(user,"phone"));
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setEdit(false);
      setSave(false);
      setCheckCode(false);
      setMyPhone(_.get(user,"phone"));
      setverifyCode("");
    }, 550);
  };

  const handleLogout = () => {
    setOpen(false);
    history.push(PAGES.LOGOUT)

  };

  const handleSaveData = () => {
    if (Helper.checkEmpty(myphone)) {
      if (myphone === _.get(user,"phone")) {
        enqueueSnackbar(Languaje.t("MozoBot.login.more.snackCheckMyPhoneSame"), {
          variant: "error"
        });
      } else {
        setSave(true);
        var sd = services.addPhone({ phone: myphone });
        sd.then((value) => {
          user.phone = myphone;
          setCheckCode(true);
        }).catch((error) => {
          setSave(false);
          enqueueSnackbar(Languaje.t("MozoBot.login.more.snackSaveMyPhoneError"), {
            variant: "error"
          });
        })
      }
    } else {
      enqueueSnackbar(Languaje.t("MozoBot.login.more.noValid"), {
        variant: "error"
      });
    }
  };

  const handleVerifyCode = () => {
    if (Helper.checkEmpty(verifyCode)) {
      setCheckCode(false);
      setSave(true);
      var vc = services.checkPhone({ code: verifyCode });
      vc.then((value) => {
        handleClose();
        enqueueSnackbar(Languaje.t("MozoBot.login.more.snackSaveMyPhoneSuccess"), {
          variant: "success"
        });
      }).catch((error) => {
        setCheckCode(true);
        enqueueSnackbar(Languaje.t("MozoBot.login.more.snackCheckMyCodeError"), {
          variant: "error"
        });
      })
    } else {
      enqueueSnackbar(Languaje.t("MozoBot.login.more.noValid"), {
        variant: "error"
      });
    }
  };

  const onChangePhone = (e) => {
    setMyPhone(e.target.value);
  };

  const onChangeCode = (e) => {
    setverifyCode(e.target.value);
  };

  const handleBackBtn = () => {
    setEdit(false);
  };

  const handleBtnChangeNumber = () => {
    setSave(false);
    setCheckCode(false);
    setverifyCode("");
  };

  return (
    <div>
      <Modal
        className={classes.modalContainer}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalBody}>
            {edit ? (
              save ? (
                checkCode ? (
                  <div className={classes.userDataContainer}>
                    <table className={classes.table100} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td>
                            <div className={`${classes.headerModal} ${classes.divCenter}`}>
                              {Languaje.t("MozoBot.login.more.InputCodeHeader")}
                            </div>
                            <div className={classes.divCenter}>
                              <ThemeProvider theme={theme}>
                                <TextField
                                  label={Languaje.t("MozoBot.login.more.InputCode")}
                                  style={{
                                    width: "90%",
                                  }}
                                  fullWidth
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={verifyCode}
                                  onChange={onChangeCode}
                                />
                              </ThemeProvider>
                            </div>
                            <div className={`${classes.userDataContainer} ${classes.divCenter}`}>
                              <ButtonFlat
                                variant="contained"
                                className={classes.btnFlatBorders}
                                onClick={() => handleBtnChangeNumber()}
                              >
                                {Languaje.t("MozoBot.login.more.btnCancelMyPhone")}
                              </ButtonFlat>
                              <ButtonFlat
                                variant="contained"
                                className={classes.btnFlatBorders}
                                onClick={() => handleVerifyCode()}
                              >
                                {Languaje.t("MozoBot.login.more.btnCheckNumber")}
                              </ButtonFlat>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className={classes.userDataContainer}>
                    <Loader></Loader>
                  </div>
                  )
              ) : (
                  <div className={classes.userDataContainer}>
                    <table className={classes.table100} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td>
                            <div className={`${classes.headerModal} ${classes.divCenter}`}>
                              {Languaje.t("MozoBot.login.more.InputPhoneHeader")}
                            </div>
                            <div className={classes.divCenter}>
                              <ThemeProvider theme={theme}>
                                <TextField
                                  type="number"
                                  label={Languaje.t("MozoBot.login.more.MyFoneInput")}
                                  style={{
                                    width: "90%",
                                  }}
                                  fullWidth
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={myphone}
                                  onChange={onChangePhone}
                                />
                              </ThemeProvider>
                            </div>
                            <div className={`${classes.userDataContainer} ${classes.divCenter}`}>
                              <ButtonFlat
                                variant="contained"
                                className={classes.btnFlatBorders}
                                onClick={() => handleBackBtn()}
                              >
                                {Languaje.t("MozoBot.login.more.btnCancelMyPhone")}
                              </ButtonFlat>
                              <ButtonFlat
                                variant="contained"
                                className={classes.btnFlatBorders}
                                onClick={() => handleSaveData()}
                              >
                                {Languaje.t("MozoBot.login.more.btnSaveMyPhone")}
                              </ButtonFlat>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
            ) : (
                <div>
                  <div className={classes.userDataContainer}>
                    <table className={classes.table100} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td>
                            <table
                              className={classes.table100}
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <tbody>
                                <tr>
                                  <td className={classes.avatarArea}>
                                    <div
                                      className={classes.avatarRounder}
                                      data-initial={Helper.CalculateInitials(
                                        _.get(user,"name")
                                      )}
                                    >
                                      <ImageFadeIn
                                        src={_.get(user,"profile_pic")}
                                        onError={(e) => {
                                          
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <span className={classes.markText}>{_.get(user,"name")}</span><br></br>
                                    <span className={classes.normalText}>{_.get(user,"email")}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className={classes.btnEditArea} valign="top">
                            <Fab
                              size="small"
                              className={classes.btnRounderBorder}
                              onClick={() => handleClose()}
                            >
                              <IcomoonReact
                                iconSet={iconSet}
                                color="#666"
                                size={18}
                                icon="close"
                              />
                            </Fab>
                          </td>
                        </tr>
                        <tr>
                          {/* <td colSpan="2">
                            <table
                              className={classes.table100}
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <tbody>
                                <tr>
                                  <td className={classes.avatarArea}></td>
                                  <td>
                                    <span className={classes.markText}>{Languaje.t("MozoBot.login.more.MyFone")}</span><br></br>
                                    {_.get(user,"phone") === "" || _.get(user,"phone") == null ? (
                                      <span className={classes.normalText}>{Languaje.t("MozoBot.login.more.noPhone")}</span>
                                    ) : (
                                        <span className={classes.normalText}>{_.get(user,"phone")}</span>
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div className={`${classes.divBorders} ${classes.userDataContainer} ${classes.divCenter}`}>
                    <ButtonFlat
                      variant="contained"
                      className={classes.btnFlatBorders}
                      onClick={() => setEdit(true)}
                    >
                      {Languaje.t("MozoBot.login.more.MyFoneChange")}
                    </ButtonFlat>
                  </div> */}
                  <div className={classes.userDataContainer}>
                    <ButtonFlat
                      variant="contained"
                      color="primary"
                      className={classes.btnFlat}
                      onClick={handleLogout}
                    >
                      {Languaje.t("MozoBot.login.more.btnCloseSesion")}
                    </ButtonFlat>
                  </div>
                </div>
              )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}