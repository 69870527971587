import _ from "lodash";
import React, {useEffect} from "react";
import { useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import ReactGA from "react-ga";

function ManagerRoute({ children, ...rest }) {
    useEffect(()=>{
        let path = window.location.href;
        ReactGA.pageview(path);
    }, [])

    const qr = useSelector(state => _.get(state, `qr`,null));
    const user = useSelector(state => _.get(state, `user`,null));

    const hasQrAccess = (url) => {
        let needQr =false;
        if (!url){
            return false;
        }
        if (url.indexOf("/code") >= 0) {
            return true;
        }
        
        if (url.indexOf("/error") >= 0) {
            return true;
        }
        if (url.indexOf("/qr/") >= 0) {
            needQr=  true
        }
        if (needQr && !_.isEmpty(qr)){
            return true
        }
        if (!needQr && _.isEmpty(qr)){
            return true
        }
        return false;
    }
    const hasUserAccess = (url) => {
        let needUser =false;
        if (url.indexOf("/user/") >= 0) {
            needUser=  true
        }
        if (needUser && !_.isEmpty(user)){
            return true
        }
        if (!needUser){
            return true
        }
        return false;
    }
    const hasAccess = (url) => {
        
        if (url=="/") {
            return true;
        }
        
        return hasQrAccess(url)&& hasUserAccess(url) ;

    }


    return (
        <React.Fragment>

            <Route
                exact={rest.exact} path={rest.path}
                render={props =>
                    hasAccess(rest.path) ? (
                        <span>
                            {children}

                        </span>

                    ) : (
                            <Redirect to={{ pathname: "/" }} />
                        )
                }
            />
        </React.Fragment>
    );
}


export default ManagerRoute
