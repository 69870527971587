

export const setClient = (client) => {
  return (dispatch) => { 
      dispatch({ type: "SET_CLIENT", payload: client })
  }
}
export const setCategoriesAndProducts = (categories, products, productsByCategories,imagesProducts) => {
  return (dispatch) => { 
      dispatch({ type: "SET_CATEGORIES_PRODUCTS", payload: {categories, products, productsByCategories,imagesProducts} })
  }
}
export default {
  setClient,
  setCategoriesAndProducts
};

