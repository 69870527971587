import React from 'react';
import classesModule from "./Scrollbar.module.scss"

const Scrollbar = ({ children,className}) => {
  return (
    <React.Fragment>
      <div className={`${classesModule.Scrollbar} ${className}`}>
          {children}
      </div>
    </React.Fragment>
  );
}
export default Scrollbar;