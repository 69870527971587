import localstore from "./localstore";
const initialState = localstore.getItem("client");
const apiReducer = (state = initialState, action) => {
	
	switch (action.type) {
		
		case 'SET_CLIENT': {
			const  client  = {...state,...action.payload};
			localstore.setItem("client", client) 
			return client ;
		}
		case 'SET_CATEGORIES_PRODUCTS':{
			let newClient = state;
			if (!newClient){
				newClient = {}
			}
			newClient = {...newClient,...action.payload}
			localstore.setItem("client", newClient) 
			return { ...state,  ...newClient };
		}
		
		default:
			return state;
	}
};

export default apiReducer;
