import React from 'react';
import classes from './ErrorInput.module.scss';


const ErrorInput = ({ message,  classNameError, showErrorIcon = true }) => {
    return (
        <React.Fragment>
            {message &&
                <div className={`${classes.ErrorInput} ${classNameError}`}>
                    <span>{message}</span>
                </div>
            }
        </React.Fragment>
    )
}
export default ErrorInput