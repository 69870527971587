import _ from "lodash";
import React from 'react';
import { useSelector } from "react-redux";


export default function ManagerAccess({ children, action, condition }) {

    const access = useSelector(state => _.get(state, "session.access", {}));
    const show = ()=>{
        
        if (access && action){
            return access[action]
        }
        if (condition!==undefined && condition){
            return condition
        }
        return true;
    }
    return (
        <React.Fragment>
            {show() && children}
        </React.Fragment>
    )
}