
export const setQr = (qr) => {
  return (dispatch) => { 
      dispatch({ type: "SET_QR", payload: qr })
  }
}

export default {
  setQr
};

