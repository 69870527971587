import client from "./client"
import qr from "./qr"
import user from "./user"
import session from "./session"
import navigation from "./navigation"
import orders from "./orders"
export default {
    client,
    user,
    qr,
    session,
    navigation,
    orders
}