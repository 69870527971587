import _ from "lodash";
import actions from "./../redux/actions";
import localstore from "./../redux/reducers/localstore";
import { PAGES } from "./../utils/constants";
import Helper from "./../utils/Helper";
import ServerConnect from "./../utils/ServerConnect";
const getToken = () => {
  return _.get(localstore.getItem("session"), "token");
};
const getClient = () => {
  return localstore.getItem("client");
};

const login = (params, dispatch) => {
  return new Promise((resolve, reject) => {
    ServerConnect.requestApi("/v1/bot/auth/scanQrCode", params, null, {})
      .then((result) => {
        if (result.data) {
          const token = _.get(result, "data.token", null);
          const location = _.get(result, "data.location", null);
          const user = _.get(result, "data.customer", null);
          const access = _.get(result, "data.access", null);
          dispatch(actions.user.setUser(user));
          dispatch(actions.session.setToken(token));
          dispatch(actions.session.setAccess(access));
          dispatch(actions.session.setAccessToken(params.accessToken));
          
          resolve(user);
        } else {
          reject();
        }
      })
      .catch((e) => {
        reject();
      });
  });
};

const loginInvite = (params, dispatch) => {
  return new Promise((resolve, reject) => {
    ServerConnect.requestApi("/v1/bot/auth/getInviteSession", params, null, {}).then((result) => {
        if (result.data) {
          const token = _.get(result, "data.token", null);
          const location = _.get(result, "data.location", null);
          const user = _.get(result, "data.customer", null);
          const access = _.get(result, "data.access", null);
          // dispatch(actions.user.setUser(user));
          dispatch(actions.session.setToken(token));
          dispatch(actions.client.setClient(location));
          dispatch(actions.qr.setQr({qr_uuid:params.qr_uuid,codeType: _.get(result,"data.codeType")}));
          dispatch(actions.session.setAccess(access));
          resolve();
        } else {
          reject();
        }
      })
      .catch((e) => {
        reject();
      });
  });
};
const crearAccessToken = (dispatch)=>{
  dispatch(actions.session.setAccessToken(null));
  
}
const logout = (qr, dispatch) => {
  dispatch(actions.user.setUser(null));
  dispatch(actions.session.setAccessToken(null));
  return loginInvite({ qr_uuid: qr.qr_uuid }, dispatch);
};

/*
redirectAction = {
  action: nombre de la fucnion de donde fue invokada
  params : {} un objeto con todos los atributos que se le pasan a la funcion
}
*/
const lostSession = (qr, redirectAction,dispatch) => {
  dispatch(actions.navigation.setRedirectPage(PAGES.PRODUCTS,redirectAction));
  return loginInvite({ qr_uuid: qr.qr_uuid }, dispatch);
};

const callMozo = (params, config, qr,history,dispatch) => { 
  return new Promise(async (resolve, reject) => {
    if (!params) {
      params = {};
    }
    params["action"] = "callMozo";
    try {
      await ServerConnect.requestApi("/v1/bot/app/actions", params, getToken(), {}, config);
      if (_.get(config,"snakbar")){
        config.snakbar(config.successMessage, {
          variant: 'success',
        });
      }
    } catch (error) {
      if (dispatch) {
        lostSession(qr,{action:"callMozo", params:{params,config,qr,history} },dispatch)
      }
      if(history)history.push(Helper.generateUrl(PAGES.LOGIN));
    }
    resolve();
  });
};

const callCount = (params, config, qr,history,dispatch) => {
  return new Promise(async (resolve, reject) => {
    if (!params) {
      params = {};
    }
    params["action"] = "callCount";
    try {
      await ServerConnect.requestApi("/v1/bot/app/actions", params, getToken(), {}, config);
      if (_.get(config,"snakbar")){
        config.snakbar(config.successMessage, {
          variant: 'success',
        });
      }
    } catch (error) {
      if (dispatch) lostSession(qr,{action:"callCount", params:{params,config,qr,history} },dispatch)
      if(history)history.push(Helper.generateUrl(PAGES.LOGIN));
    }
    resolve();
  });
};
const getMyOrder = (params,config,qr,history,dispatch) => {
  return new Promise(async (resolve, reject) => {
    if (!params) {
      params = {};
    }
    params["action"] = "getOrder";
    try {
      const result=  await ServerConnect.requestApi("/v1/bot/app/getMyOrder", params, getToken(), {},config)
      resolve(result)
    } catch (error) {
     reject(error)
    }
    resolve();
  });
};

const addProduct = (params, config, qr,history, dispatch) => {
  return new Promise(async (resolve, reject) => {
    if (!params) {
      params = {};
    }
    params["action"] = "newProduct";
    try {
      await ServerConnect.requestApi("/v1/bot/app/order", params, getToken(), {}, config)
      if (_.get(config,"snakbar")){
        config.snakbar(config.successMessage, {
          variant: 'success',
        });
      }
    } catch (error) {
      if (dispatch) lostSession(qr,{action:"addProduct", params:{params,config,qr,history} },dispatch)
      if(history)history.push(Helper.generateUrl(PAGES.LOGIN));
    }
    resolve();
  });
};

const addPhone = (params, dispatch) => {
  // TODO agregar el número de teléfono y enviar el código de verificación
  return new Promise((resolve, reject) => {
    if (!params) { params = {}; }
    ServerConnect.requestApi("/v1/bot/app/updatePhone", params, getToken(), {}).then((result) => {
      resolve(result);
    }).catch((e) => {
      reject(e);
    });
  });
};

const checkPhone = (params, dispatch) => {
  return new Promise((resolve, reject) => {
    if (!params) { params = {}; }
    ServerConnect.requestApi("/v1/bot/app/checkPhone", params, getToken(), {}).then((result) => {
      resolve(result);
    }).catch((e) => {
      reject(e);
    });
  });
};
const refreshActiveCodePhone = (params, dispatch) => {
  return new Promise((resolve, reject) => {
    if (!params) { params = {}; }
    ServerConnect.requestApi("/v1/bot/app/refreshActiveCodePhone", params, getToken(), {}).then((result) => {
      resolve(result);
    }).catch((e) => {
      reject(e);
    });
  });
};


const getCategoriesAndContainers = (params, dispatch) => {
  return new Promise((resolve, reject) => {

    let functionsServer = [];
    functionsServer.push(
      ServerConnect.requestApi("/v1/bot/app/getCategoriesMenu", {}, getToken(), {})
    );
    functionsServer.push(
      ServerConnect.requestApi("/v1/bot/app/getProductsMenu", {}, getToken(), {})
    );

    Promise.all(functionsServer).then((result) => {

      if (!result[0] || !result[1]) {
        reject("Error Server");
      }
      if (result[0].error || result[1].error) {
        reject("Error Server");
      }

      const categories = result[0] && result[0].data ? result[0].data : [];
      const products = result[1] && result[1].data ? result[1].data : [];
      let productsByCategories = [];
      let productsByCategoriesMapper = {};
      _.forEach(categories.rows, (cat) => {
        productsByCategoriesMapper[cat.id] = productsByCategories.length;
        productsByCategories.push({
          ...cat,
          products: [],
        });
      });
      _.forEach(products.rows, (prod) => {
        _.forEach(prod.Category, (cat) => {
          if (productsByCategories[productsByCategoriesMapper[cat.id]]) {
            productsByCategories[productsByCategoriesMapper[cat.id]].products.push({ ...prod });
          }
        });
      });
      const imagesProducts = {};
      _.forEach(products.rows, (product) => {
        if (product.uuid_image) {
          imagesProducts[product.id] = ServerConnect.getUrlImage(
            product.uuid_image,
            null,
            {}
          );
        } else {
          imagesProducts[product.id] = null;
        }
      });
      dispatch(actions.client.setCategoriesAndProducts(categories, products, productsByCategories, imagesProducts));
      resolve();
    })
  });
};
const saveOrderDelivery = (qr,params,history, isDelivery,dispatch) => {
  
  return new Promise((resolve, reject) => {
    const defaultParams = {
      orderLines:[],
      paymentMethod: "cash",
    };
    const url = isDelivery?"/v1/bot/app/saveOrder/delivery":"/v1/bot/app/saveOrder/unique";
    ServerConnect.requestApi(url, {...defaultParams,...params}, getToken(), {}).then(async (result) => {
      dispatch(actions.orders.clearOrder(getClient()));
      logout(qr,dispatch)
      resolve(result);
    }).catch((e) => {
      if(history)history.push(Helper.generateUrl(PAGES.LOGIN));
      reject(e);
    });
  });
};
export default {
  login,
  loginInvite,
  logout,
  callMozo,
  callCount,
  addProduct,
  addPhone,
  checkPhone,
  getMyOrder,
  refreshActiveCodePhone,
  getCategoriesAndContainers,
  saveOrderDelivery,
  crearAccessToken
};