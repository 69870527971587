const apiUri = process.env.REACT_APP_API_URL;

    

export default class ServerConnect {
    static async requestApi(path,body,token, options) { 
        const optionsSend  = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token
            },
            body:body,
            ...options
        }
        optionsSend.body = JSON.stringify(optionsSend.body);
        try{
            
            const response = await fetch(apiUri + path, optionsSend);
            if (response.status==200){
                const json = await response.json();
                return json;
            }if (response.status==403 || response.status==401 ){
                throw  response.status;
            }else{ 
                throw  response.status;
            }
        }catch(e){
            console.log(e);
            throw e;
        }
    }
    
    static async getImage(uuid,size, options) {
        const optionsSend  = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            ...options
        }
        size = size?size:"mozoapp";
        const img = await fetch(apiUri + "/v1/api/services/image/get/img/"+ uuid+"/"+size, optionsSend);
        
        if (img && img.data && img.data.img) {
            
            return img.data.img
          }
        return null;
    }
    static  getUrlImage(uuid,size, options) {
        
        size = size?size:"mozoapp";
        return apiUri + "/v1/api/services/image/get/img/"+ uuid+"/"+size;
        
    }
}
