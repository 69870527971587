import React from 'react';
import { useFormContext } from 'react-hook-form';
import Button from '../../../../../components/Button/Button';


const ButtonSubmit = ({ isSubmitting, handleDisabled, ...props }) => {
    const { formState } = useFormContext();
    const { isSubmitting: isSubmittingForm, isValid } = formState;
    

    const disabledButton = () => {
        return handleDisabled ? handleDisabled(formState) : (isSubmitting || !isValid)
    };

    return (
        <React.Fragment>
            <Button  color={"primary"} 
                loading={isSubmitting || isSubmittingForm}
                disabled={disabledButton()} {...props} type={"submit"}  > {props.children} </Button>
        </React.Fragment>
    )
};

export default ButtonSubmit
