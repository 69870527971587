
const getItem = (key) => {
    try {
        const serializedData = localStorage.getItem(key);
        if (serializedData === null) {
            return {};
        }
        const jsonData = JSON.parse(serializedData);
        return jsonData;
    } catch (error) {
        console.log("Load Error", error)
        return {};
    }
}
const setItem = (key, state) => {
    try {
        let serializedData = JSON.stringify(state)
        localStorage.setItem(key, serializedData)
    } catch (error) {
        console.log("Save Error", error)
    }
}


export default {
    setItem,
    getItem
}