import _ from "lodash";
import React, { useState } from "react";
import classes from "./SliderHorizontal.module.scss";

const SliderHorizontal = ({ panels, close, defaultValue = 0, ...props }) => {

  const [panel, setPanel] = useState(defaultValue);

  const nextPanel = () => {
    setPanel(panel + 1);
  };

  const prevPanel = () => {
    setPanel(panel - 1);
  };

  return (
    <div
      className={`${classes.SliderHorizontal}`}
      style={{ left: `-${100 * panel}%`, width: `${panels.length * 100}%` }}
    >
      {_.map(panels, (panel, i) => {
        return (
          <div key={i}>
            {React.createElement(panel, { nextPanel, prevPanel, close })}
          </div>
        );
      })}
    </div>
  );
};

export default SliderHorizontal;
